export default function clients (state, action) {
  state = state || {
    clientList: [],
    clients: {},
    error: null,
    fetching: true,
    fetchedAt: null,
    lessons: []
  }

  switch (action.type) {
    // List
    case 'CLIENT_LIST':
    case 'CLIENT_GET':
      return Object.assign({}, state, {
        fetching: true
      })
    case 'CLIENT_LIST_SUCCESS':
      return Object.assign({}, state, {
        fetching: false,
        fetchedAt: Date.now(),
        clients: Object.assign({}, state.clients, action.clients),
        clientList: action.clientList
      })
    case 'CLIENT_LIST_FAILURE':
    case 'CLIENT_GET_FAILURE':
      return Object.assign({}, state, {
        fetching: false,
        error: action.error
      })
    case 'CLIENT_FETCH_SUCCESS':
      return Object.assign({}, state, {
        clients: Object.assign({}, state.clients, action.clients)
      })

    // Get
    case 'CLIENT_GET_SUCCESS':
      return Object.assign({}, state, {
        clients: { ...state.clients, [action.client.id]: action.client }
      })

    case 'CLIENT_UPDATE_SUCCESS':
      return Object.assign({}, state, {
        fetching: false,
        fetchedAt: Date.now(),
        clients: { ...state.clients, ...{ [action.client.id]: action.client } }
        // ...action.client,
        // // and add all of our current clients, except any with the same ID
        // ...state.clients.filter(client => {
        //   return client.id !== action.client.id
        // })
      })

    case 'CLIENT_UPDATE_FAILURE':
      return Object.assign({}, state, {
        fetching: false,
        error: action.error
      })

    case 'CLIENT_LESSONS_FETCHED':
      return Object.assign({}, state, {
        clients: Object.assign({}, state.clients, {
          [action.clientId]: Object.assign({}, state.clients[action.clientId], {
            lessons: action.lessons
          })
        })
      })

    default:
      return state
  }
}
