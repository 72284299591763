import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Button,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  UncontrolledCollapse
} from 'reactstrap'

import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import InlineCss from 'react-inline-css'

const schoolBookingURL = process.env.REACT_APP_SB_URL

const Navigation = props => {
  if (props.fetchingUser) {
    return null
  }

  const isUsingSchoolBookingNav = props.user.user && props.rights && props.rights[props.user.user.id]

  if (isUsingSchoolBookingNav) {
    const clientUserId = Object.keys(props.user.clientUsers)[0]
    if (!clientUserId) {
      return null
    }
    const handleExternalNavigation = url => {
      localStorage.removeItem('apiToken')
      window.location.href = url
    }

    const clientUser = props.user.clientUsers[clientUserId]
    const clientId = clientUser.client_id
    const client = props.clients[clientId]
    const hasBetaAccess = client?.settings?.allow_beta_access === true // eslint-disable-line
    const rights = props.rights[props.user.user.id] || {}
    const hasBookings = parseInt(rights.has_bookings, 10) === 1
    const hasLettings = parseInt(rights.has_lettings, 10) === 1
    const hasReports = parseInt(rights.has_reports, 10) === 1
    const isAdmin = parseInt(rights.has_admin, 10) === 1

    const handleRedirectToBeta = () => {
      let redirectUrl = process.env.REACT_APP_BETA_ADMIN_PORTAL_URL

      const eventIdMatch = window.location.href.match(/\/(events|reports|attendance|itinerary|activity|timetable)\/[0-9]+/m)

      if (eventIdMatch) {
        const eventId = eventIdMatch[0].replace(/\/(events|reports|attendance|itinerary|activity|timetable)\//g, '')
        redirectUrl = `${process.env.REACT_APP_BETA_ADMIN_PORTAL_URL}/event/${eventId}/dashboard`
      }

      const encodedUser = btoa(JSON.stringify({
        redirectTo: redirectUrl,
        token: localStorage.getItem('apiToken')
      }))

      window.open(`${process.env.REACT_APP_BETA_ADMIN_PORTAL_URL}/auth/${encodedUser}`, '_blank')
    }

    const {
      menu_background_color: menuBackgroundColor,
      menu_background_color_active: menuBackgroundColorActive,
      menu_background_color_hover: menuBackgroundColorHover,
      menu_display_name: menuDisplayName,
      menu_display_name_color: menuDisplayNameColor,
      menu_display_name_size: menuDisplayNameSize,
      menu_dropdown_background_color: menuDropdownBackgroundColor,
      menu_dropdown_color: menuDropdownColor,
      menu_item_color: menuItemColor,
      menu_item_color_active: menuItemColorActive,
      menu_item_color_hover: menuItemColorHover
    } = client
    return (
      <InlineCss
        stylesheet={`
        & .navbar {
          ${menuBackgroundColor && `background-color: #${menuBackgroundColor};`}
        }
        & .navbar .navbar-brand {
          padding: 4px;
          ${menuDisplayNameColor && `color: #${menuDisplayNameColor};`}
          ${menuDisplayNameSize && `font-size: ${menuDisplayNameSize}px;`}
        }
        & .nav-item > a {
          cursor: pointer;
          padding: 7px 15px;
          ${menuItemColor && `color: #${menuItemColor};`}
        }
        & .nav-item.active > a {
          ${menuBackgroundColorActive && `background-color: #${menuBackgroundColorActive};`}
          ${menuItemColorActive && `color: #${menuItemColorActive};`}
        }
        & .nav-item > a:hover,
        & .nav-item > a:focus {
          ${menuBackgroundColorHover && `background-color: #${menuBackgroundColorHover};`}
          ${menuItemColorHover && `color: #${menuItemColorHover};`}
        }
        & .dropdown-toggle {
          ${menuDropdownBackgroundColor && `background-color: #${menuDropdownBackgroundColor};`}
          ${menuDropdownColor && `color: #${menuDropdownColor};`}
        }
      `}
      >
        <Navbar expand='lg' className='mb-3 sb-nav' dark>
          <NavbarBrand href={`${schoolBookingURL}/apps/bookings`}>
            <span className='logobar' id='logotext' title='Back to Dashboard'>
              {menuDisplayName || client.name}
            </span>
          </NavbarBrand>
          <NavbarToggler id='toggler' />
          <UncontrolledCollapse toggler='#toggler' navbar>
            <Nav className='ml-auto mb-2 mb-lg-0'>
              {hasBookings && (
                <NavItem onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/bookings`)}>
                  <NavLink>Bookings</NavLink>
                </NavItem>
              )}
              {hasLettings && (
                <NavItem onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/let`)}>
                  <NavLink>Lettings</NavLink>
                </NavItem>
              )}
              <LinkContainer to='/'>
                <NavItem>
                  <NavLink>Parents</NavLink>
                </NavItem>
              </LinkContainer>
              {hasReports && (
                <NavItem onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/reports`)}>
                  <NavLink>Reports</NavLink>
                </NavItem>
              )}
              {isAdmin && (
                <NavItem onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/administration`)}>
                  <NavLink>Administration</NavLink>
                </NavItem>
              )}
              <Dropdown nav isOpen={props.isNavVisible} toggle={() => props.handleNavToggle(!props.isNavVisible)}>
                <DropdownToggle nav caret>
                  {props.user.user && props.user.user.name}
                </DropdownToggle>
                <DropdownMenu className='user-menu' right>
                  <DropdownItem
                    onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/bookings/myprofile.php`)}
                  >
                    My Profile
                  </DropdownItem>
                  <DropdownItem
                    onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/bookings/myfavourites.php`)}
                  >
                    My Favourites
                  </DropdownItem>
                  <DropdownItem divider />
                  {hasBookings && (
                    <DropdownItem onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/bookings`)}>
                      Bookings
                    </DropdownItem>
                  )}
                  {hasLettings && (
                    <DropdownItem onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/let/`)}>
                      Lettings
                    </DropdownItem>
                  )}
                  <LinkContainer to='/' active>
                    <DropdownItem active>Events</DropdownItem>
                  </LinkContainer>
                  {isAdmin && (
                    <DropdownItem
                      onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/administration/`)}
                    >
                      Administration
                    </DropdownItem>
                  )}
                  {hasReports && (
                    <DropdownItem onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/reports`)}>
                      Reports
                    </DropdownItem>
                  )}
                  {isAdmin && <DropdownItem divider />}
                  {isAdmin && (
                    <DropdownItem
                      onClick={handleExternalNavigation.bind(
                        null,
                        `${schoolBookingURL}/apps/administration/support.php`
                      )}
                    >
                      Support
                    </DropdownItem>
                  )}
                  {isAdmin && (
                    <DropdownItem
                      onClick={handleExternalNavigation.bind(
                        null,
                        `${schoolBookingURL}/apps/administration/licence/licence.php`
                      )}
                    >
                      Licence
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={handleExternalNavigation.bind(null, `${schoolBookingURL}/apps/shared/signout.php`)}
                  >
                    Log out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {hasBetaAccess && (
                <Button color='primary' size='sm' onClick={() => handleRedirectToBeta()} style={{
                  height: '36px',
                  marginTop: '4px',
                  marginLeft: '24px',
                  fontWeight: 600
                }}>Parents beta version</Button>
              )}
            </Nav>
          </UncontrolledCollapse>
        </Navbar>
      </InlineCss>
    )
  } else {
    return (
      <Navbar light expand='md' className='bg-white mb-3 shadow-sm'>
        <NavbarBrand href='/'>
          <img src='/logo.png' alt='SchoolBooking Logo' width='58' />
        </NavbarBrand>
        <NavbarToggler onClick={() => props.handleNavToggle(!props.isNavVisible)} />
        <Collapse isOpen={props.isNavVisible} navbar>
          <Nav className='ml-auto' navbar>
            {props.user.user && [
              <NavItem active={props.location.pathname.indexOf('account') === -1} key='navEvents'>
                <NavLink tag={Link} to='/'>
                  Events
                </NavLink>
              </NavItem>,
              <NavItem active={props.location.pathname.indexOf('account') > -1} key='navAccount'>
                <NavLink tag={Link} to='/account'>
                  Account
                </NavLink>
              </NavItem>
            ]}

            {/**
              <NavItem onClick={props.onHelpTrigger}>
                <NavLink className='clickable'>Need Help?</NavLink>
              </NavItem>
            */}
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

Navigation.propTypes = {
  handleNavToggle: PropTypes.func.isRequired,
  isNavVisible: PropTypes.bool.isRequired,
  onHelpTrigger: PropTypes.func
}

function mapStateToProps (state) {
  return {
    fetchingUser: state.user.fetchingUser,
    clients: state.clients.clients,
    rights: state.rights.rights,
    user: state.user
  }
}

function mapDispatchToProps (dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navigation))
