import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import { user } from '../../../actions'
import { connect } from 'react-redux'

const ImpersonatedUserBanner = ({ handleRevertLoginAs, user }) => (
  <div className='impersonator-banner'>
    <div className='d-flex align-items-center'>
      <FontAwesomeIcon className='mr-2' icon={faUserSecret} size='lg' />
      <p className='mb-0'>
        You are currently logged in as <strong>{user.name}</strong>
      </p>
    </div>

    <Button color='link' size='sm' onClick={() => handleRevertLoginAs()}>
      Revert to your Login <FontAwesomeIcon icon={faAngleRight} />
    </Button>
  </div>
)

ImpersonatedUserBanner.propTypes = {
  user: PropTypes.object.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    handleRevertLoginAs: () => {
      dispatch(user.revertLoginAs())
      window.location.href = '/'
    }
  }
}

export default connect(null, mapDispatchToProps)(ImpersonatedUserBanner)
