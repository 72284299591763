import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const defaultIncludes = []

const comments = {
  create: (linkedModelName, linkedModelId, parentId, eventId, comment, includes = defaultIncludes) => {
    return dispatch => {
      return APIRequest.post(
        dispatch,
        `events/${eventId}/${linkedModelName}/${linkedModelId}/comments`,
        { comment },
        includes
      )
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.comment)
          const comment = output.entities.comments[output.result]

          dispatch({
            type: 'COMMENT_CREATE_SUCCESS',
            comment
          })

          if (linkedModelName === 'bookingSlot') {
            dispatch({
              type: 'BOOKING_SLOT_COMMENT_CREATE_SUCCESS',
              comment: comment,
              bookingId: parentId,
              bookingSlotId: linkedModelId
            })
          }
        })
        .catch(err => {
          dispatch({ type: 'COMMENT_CREATE_FAILURE', comment, error: err.message })
          throw err
        })
    }
  },

  delete: (linkedModelName, linkedModelId, parentId, commentId, includes = defaultIncludes) => {
    return dispatch => {
      return APIRequest.delete(dispatch, `comments/${commentId}`, {}, includes)
        .then(res => {
          if (linkedModelName === 'bookingSlot') {
            dispatch({
              type: 'BOOKING_SLOT_COMMENT_DELETE_SUCCESS',
              commentId: commentId,
              bookingId: parentId,
              bookingSlotId: linkedModelId
            })
          }

          return dispatch({
            type: 'COMMENT_DELETE_SUCCESS',
            commentId
          })
        })
        .catch(err => {
          dispatch({ type: 'COMMENT_DELETE_FAILURE', commentId, error: err.message })
          throw err
        })
    }
  },

  update: (comment, commentId, read, includes = defaultIncludes) => {
    return dispatch => {
      return APIRequest.patch(dispatch, `comments/${commentId}`, { comment, read }, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.comment)
          const comment = output.entities.comments[output.result]

          dispatch({
            type: 'COMMENT_UPDATE_SUCCESS',
            comment
          })
        })
        .catch(err => {
          dispatch({ type: 'COMMENT_UPDATE_FAILURE', comment, error: err.message })
          throw err
        })
    }
  }
}

export default comments
