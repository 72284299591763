export default function rights (state, action) {
  state = state || {
    rights: {}
  }

  switch (action.type) {
    case 'RIGHTS_FETCHED':
      return Object.assign({}, state, {
        rights: Object.assign({}, state.rights, action.rights)
      })

    default:
      return state
  }
}
