export default function locations (state, action) {
  state = state || {
    error: null,
    fetchedAt: null,
    fetchingLocations: false,
    locations: {},
    locationsList: []
  }

  switch (action.type) {
    case 'LOCATIONS_FETCHED':
      return Object.assign({}, state, {
        locations: Object.assign({}, state.locations, action.locationList)
      })
    case 'LOCATION_LIST':
      return Object.assign({}, state, {
        fetchingLocations: true
      })
    case 'LOCATION_LIST_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        fetchingLocations: false
      })
    case 'LOCATION_LIST_SUCCESS':
      return Object.assign({}, state, {
        fetchedAt: new Date(),
        fetchingLocations: false,
        locations: Object.assign({}, state.locations, action.locations),
        locationsList: action.locationsList
      })

    default:
      return state
  }
}
