import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import bookings from './bookings'
import clients from './clients'
import comments from './comments'
import display from './display'
import events from './events'
import eventNotificationRecipientTypes from './eventNotificationRecipientTypes'
import eventNotificationStatus from './eventNotificationStatus'
import eventNotificationTypes from './eventNotificationTypes'
import groups from './groups'
import groupLessonNotes from './groupLessonNotes'
import guardians from './guardians'
import guardianStudents from './guardianStudents'
import guestInvitations from './guestInvitations'
import imports from './imports'
import invitations from './invitations'
import invitationGroups from './invitationGroups'
import itineraries from './itineraries'
import importTypes from './importTypes'

import lessons from './lessons'
import locations from './locations'
import log from './log'

import meetingTypes from './meetingTypes'
import misIntegrator from './misIntegrator'
import notifications from './notifications'
import rights from './rights'
import sessions from './sessions'
import slots from './slots'
import staff from './staff'
import steps from './steps'
import students from './students'
import subjects from './subjects'
import user from './user'
import venues from './venues'

// add value to white list here to allow persistence of a redux node
const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
}

// This is where we will import all of our reducers and then combine them
// so we can create a store from them in the index
const rootReducer = combineReducers({
  bookings,
  clients,
  comments,
  display,
  events,
  eventNotificationRecipientTypes,
  eventNotificationStatus,
  eventNotificationTypes,
  groups,
  groupLessonNotes,
  guardians,
  guardianStudents,
  guestInvitations,
  imports,
  importTypes,
  invitations,
  invitationGroups,
  itineraries,
  lessons,
  locations,
  log,
  meetingTypes,
  misIntegrator,
  notifications,
  rights,
  sessions,
  slots,
  staff,
  students,
  steps,
  subjects,
  user,
  venues
})

export default persistReducer(persistConfig, rootReducer)
