import superagent from 'superagent'

export default class GraphQLRequest {
  constructor () {
    this.endpoint = process.env.REACT_APP_GRAPHQL_URL
  }

  send (payload) {
    return superagent
      .post(this.endpoint)
      .send(payload)
      .set('Authorization', `Bearer ${localStorage.getItem('apiToken')}`)
      .then(response => {
        const { body } = response

        if (body.errors) throw body.errors[0]

        return body.data
      })
  }
}
