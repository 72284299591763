export default function groups (state, action) {
  state = state || {
    groups: {}
  }

  switch (action.type) {
    case 'GROUPS_FETCHED':
      return Object.assign({}, state, {
        groups: Object.assign(
          {},
          state.groups,
          Object.keys(action.groups).reduce(function (acc, groupId) {
            const existingGroups = state.groups[groupId]
            acc[groupId] = Object.assign(
              {},
              {
                sessions: existingGroups && existingGroups.sessions ? existingGroups.sessions : [],
                lessons: existingGroups && existingGroups.lessons ? existingGroups.lessons : []
              },
              action.groups[groupId]
            )
            return acc
          }, {})
        )
      })
    case 'GROUP_CREATE_SUCCESS':
      return Object.assign({}, state, {
        groups: Object.assign({}, state.groups, action.groups)
      })
    case 'GROUP_UPDATE_SUCCESS':
      const existingGroup = state.groups[action.id]
      return Object.assign({}, state, {
        groups: Object.assign({}, state.groups, {
          [action.id]: Object.assign(
            {},
            {
              sessions: existingGroup && existingGroup.sessions ? existingGroup.sessions : [],
              lessons: existingGroup && existingGroup.lessons ? existingGroup.lessons : []
            },
            action.group
          )
        })
      })

    case 'GROUP_LESSON_NOTE_DELETED':
      if (!state.groups[action.groupId].groupLessonNotes) {
        return state
      }

      const group = {
        ...state.groups[action.groupId],
        groupLessonNotes: state.groups[action.groupId].groupLessonNotes.filter(id => id !== action.id)
      }

      return { ...state, groups: { ...state.groups, [action.groupId]: group } }

    case 'GROUP_LESSON_NOTE_UPDATED':
      return state

    case 'GROUP_LESSON_NOTE_CREATED':
      if (!state.groups[action.groupId].groupLessonNotes) {
        return state
      }

      const newGroup = { ...state.groups[action.groupId] }

      newGroup.groupLessonNotes.push(action.id)

      return { ...state, groups: { ...state.groups, [action.groupId]: newGroup } }

    case 'GROUP_DELETE_SUCCESS':
      let newState = Object.assign({}, state)
      newState.groups = Object.assign({}, state.groups)
      delete newState.groups[action.groupId]
      return newState
    default:
      return state
  }
}
