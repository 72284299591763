export default function subjects (state, action) {
  state = state || {
    subjects: {}
  }

  switch (action.type) {
    case 'SUBJECTS_FETCHED':
      return Object.assign({}, state, {
        subjects: Object.assign({}, state.subjects, action.subjects)
      })
    default:
      return state
  }
}
