export default function sessions (state, action) {
  state = state || {
    sessions: {},
    error: null,
    fetchingSessions: false,
    validation: null
  }

  switch (action.type) {
    case 'DISMISS_ERROR':
      return Object.assign({}, state, {
        error: null
      })

    case 'SESSION_ADD':
    case 'SESSION_SAVE':
    case 'SESSION_REMOVE':
      return Object.assign({}, state, {
        fetchingSessions: true
      })

    case 'SESSION_ADD_SUCCESS':
    case 'SESSION_SAVE_SUCCESS':
      const existingSession = state.sessions[action.session.id]
      return Object.assign({}, state, {
        fetchingSessions: false,
        sessions: Object.assign({}, state.sessions, {
          // if we're updating an existing session, and it already has group records, but the updated version doesn't, use the old groups values
          [action.session.id]: Object.assign({}, {
            groups: (existingSession && existingSession.groups) ? existingSession.groups : []
          }, action.session)
        })
      })

    case 'SESSION_ADD_FAILURE':
    case 'SESSION_SAVE_FAILURE':
    case 'SESSION_REMOVE_FAILURE':
      return Object.assign({}, state, {
        fetchingSessions: false
      })

    case 'SESSION_REMOVE_SUCCESS':
      let amendedSessions = Object.assign({}, state.sessions)
      delete amendedSessions[action.id]
      return Object.assign({}, state, {
        fetchingSessions: false,
        sessions: amendedSessions
      })

    case 'SESSION_UPDATE':
      return Object.assign({}, state, {
        sessions: Object.assign(
          {},
          state.sessions,
          {
            [action.id]: Object.assign(
              {},
              state.sessions[action.id],
              {
                [action.property]: action.value
              }
            )
          }
        )
      })

    case 'NEW_SESSION_REMOVE':
      return Object.assign({}, state, {
        newSessions: state.newSessions.filter((session, index) => index !== action.id)
      })

    case 'SESSIONS_FETCHED':
      return Object.assign({}, state, {
        // merge in the sessions, however, if the new session doesn't have a groups value, and the old sessions do, keep the groups value from the old
        sessions: Object.assign({}, state.sessions, Object.keys(action.sessions)
          .reduce(function (acc, sessionId) {
            const existingSession = state.sessions[sessionId]
            acc[sessionId] = Object.assign({}, {
              groups: (existingSession && existingSession.groups) ? existingSession.groups : []
            }, action.sessions[sessionId])
            return acc
          }, {})
        )
      })

    case 'SESSIONS_ADD_GROUP_SUCCESS':
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, action.sessionIds.reduce((addedSessions, sessionId) => {
          const existingSession = state.sessions[sessionId] || {
            groups: []
          }
          const sessionGroups = existingSession.groups || []
          const groupIds = [
            ...sessionGroups,
            action.groupId
          ]

          addedSessions[sessionId] = Object.assign({}, existingSession, {
            groups: groupIds
          })
          return addedSessions
        }, {}))
      })

    case 'SESSIONS_REMOVE_GROUP_SUCCESS':
      return Object.assign({}, state, {
        sessions: Object.assign({}, state.sessions, action.sessionIds.reduce((reducedSessions, sessionId) => {
          const groups = [
            ...state.sessions[sessionId].groups.filter(groupId => groupId !== action.groupId)
          ]
          reducedSessions[sessionId] = Object.assign({}, state.sessions[sessionId], {
            groups: groups
          })
          return reducedSessions
        }, {}))
      })

    case 'SESSION_VALIDATION':
      return Object.assign({}, state, {
        validation: action.validation
      })

    case 'SESSION_VALIDATION_CLEAR':
      return Object.assign({}, state, {
        validation: null
      })

    default:
      return state
  }
}
