export default function invitations (state, action) {
  state = state || {
    invitations: {}
  }

  switch (action.type) {
    case 'INVITATIONS_FETCHED':
      return Object.assign({}, state, {
        invitations: action.invitations
      })
    case 'INVITATION_GET_SUCCESS':
      return Object.assign({}, state, {
        invitations: Object.assign({}, state.invitations, {
          [action.invitation.id]: action.invitation
        })
      })

    case 'INVITATION_UPDATE_SUCCESS':
      return { ...state, invitations: { ...state.invitations, [action.invitation.id]: action.invitation } }

    case 'INVITATION_DELETE_SUCCESS':
      let stateWithInvitationDeleted = Object.assign({}, state)
      stateWithInvitationDeleted.invitations = Object.assign({}, state.invitations)
      delete stateWithInvitationDeleted.invitations[action.invitationId]
      return stateWithInvitationDeleted
    default:
      return state
  }
}
