export default function imports (state, action) {
  state = state || {
    currentImport: null,
    error: null,
    importing: false,
    exporting: false,
    fetchingSummary: false,
    dataDumpCsv: null,
    summary: {}
  }

  switch (action.type) {
    case 'CURRENT_IMPORT_SUCCESS':
      return Object.assign({}, state, {
        currentImport: action.import
      })
    case 'EXPORT_DATA_DUMP_STARTED':
      return Object.assign({}, state, {
        exporting: true
      })
    case 'EXPORT_DATA_DUMP_SUCCESS':
      return Object.assign({}, state, {
        exporting: false,
        dataDumpCsv: action.data
      })
    case 'EXPORT_DATA_DUMP_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        exporting: false
      })
    case 'IMPORT':
      return Object.assign({}, state, {
        importing: true
      })
    case 'IMPORT_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        importing: false
      })
    case 'IMPORT_STARTED':
      return Object.assign({}, state, {
        currentImport: Object.assign({}, state.currentImport, action.currentImport)
      })
    case 'IMPORT_SUMMARY':
      return Object.assign({}, state, {
        fetchingSummary: true
      })
    case 'IMPORT_SUMMARY_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        fetchingSummary: false
      })
    case 'IMPORT_SUMMARY_SUCCESS':
      return Object.assign({}, state, {
        fetchingSummary: false,
        summary: Object.assign({}, state.summary, action.summary)
      })
    default:
      return state
  }
}
