export default function notifications (state, action) {
  state = state || {
    notifications: []
  }

  switch (action.type) {
    case 'NOTIFICATION_ADD':
      return Object.assign({}, state, {
        notifications: [...state.notifications, action.message]
      })

    default:
      return state
  }
}
