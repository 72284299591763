export default function slots (state, action) {
  state = state || {
    error: null,
    slots: {}
  }

  switch (action.type) {
    case 'SLOTS_FETCHED':
      return Object.assign({}, state, {
        slots: Object.assign({}, state.slots, action.slots)
      })

    case 'SLOT_REMOVE_BOOKING_SUCCESS':
      const stateSlot = state.slots[action.slotId]
      if (stateSlot && stateSlot.bookings) {
        return Object.assign({}, state, {
          slots: Object.assign({}, state.slots, {
            [action.slotId]: Object.assign({}, stateSlot, {
              bookings: stateSlot.bookings.filter(booking => booking.id !== action.bookingId),
              remaining_capacity: stateSlot.remaining_capacity + 1
            })
          })
        })
      }
      return state

    case 'SLOT_UPDATE_SUCCESS':
      return Object.assign({}, state, {
        slots: Object.assign({}, state.slots, {
          [action.slot.id]: Object.assign({}, state.slots[action.slot.id], action.slot)
        })
      })

    default:
      return state
  }
}
