import { schema } from 'normalizr'

const SchemaService = {
  schemas: {
    booking: new schema.Entity(
      'bookings',
      {},
      {
        processStrategy: (value, parent, key) => {
          if (value.bookingSlots && value.bookingSlots.length > 0 && value.bookingSlots[0].comments) {
            const newBookingSlots = value.bookingSlots.map(({ ...bookingSlot }, index) => {
              bookingSlot.comments = [...bookingSlot.comments.map(comment => comment.id)]
              return bookingSlot
            })

            return { ...value, bookingSlots: newBookingSlots }
          }

          return value
        }
      }
    ),
    client: new schema.Entity(
      'clients',
      {},
      {
        processStrategy: (value, parent, key) => (value.settings ? { ...value, settings: value.settings } : value)
      }
    ),
    clientUser: new schema.Entity('clientUser'),
    comment: new schema.Entity('comments'),
    groupLessonNote: new schema.Entity('groupLessonNotes'),
    guestInvitation: new schema.Entity('guestInvitations'),
    event: new schema.Entity('events'),
    group: new schema.Entity(
      'groups',
      {},
      {
        mergeStrategy: (InitialEntity, NewEntity) => {
          return Object.assign({}, NewEntity, {
            lessons: [
              ...(InitialEntity.lessons || []),
              ...((NewEntity.lessons &&
                NewEntity.lessons.filter(lessonId =>
                  InitialEntity.lessons ? InitialEntity.lessons.indexOf(lessonId) === -1 : true
                )) ||
                [])
            ],
            sessions: [
              ...(InitialEntity.sessions || []),
              ...((NewEntity.sessions &&
                NewEntity.sessions.filter(sessionId =>
                  InitialEntity.sessions ? InitialEntity.sessions.indexOf(sessionId) === -1 : true
                )) ||
                [])
            ]
          })
        },
        processStrategy: (value, parent, key) => {
          if (parent.hasOwnProperty('slot_gap')) {
            // the parent is a session
            value = Object.assign({}, value, {
              sessions: value.sessions ? value.sessions : [parent.id]
            })
          }
          return value
        }
      }
    ),
    guardian: new schema.Entity('guardians'),
    guardianStudent: new schema.Entity('guardianStudents'),
    invitation: new schema.Entity('invitations'),
    invitationGroup: new schema.Entity('invitationGroups'),
    lesson: new schema.Entity(
      'lessons',
      {},
      {
        mergeStrategy: (InitialEntity, NewEntity) => {
          return Object.assign({}, NewEntity, {
            groups: [
              ...(InitialEntity.groups || []),
              ...((NewEntity.groups &&
                NewEntity.groups.filter(groupId =>
                  InitialEntity.groups ? InitialEntity.groups.indexOf(groupId) === -1 : true
                )) ||
                [])
            ]
          })
        },
        processStrategy: (value, parent, key) => {
          if (parent.hasOwnProperty('owner_id')) {
            // the parent is a group
            value = Object.assign({}, value, {
              groups: value.groups ? value.groups : [parent.id]
            })
          }
          return value
        }
      }
    ),
    location: new schema.Entity('location'),
    right: new schema.Entity(
      'rights',
      {},
      {
        idAttribute: function (value, parent) {
          return parent.id
        }
      }
    ),
    role: new schema.Entity('role'),
    session: new schema.Entity(
      'sessions',
      {},
      {
        mergeStrategy: (InitialEntity, NewEntity) => {
          return Object.assign({}, NewEntity, {
            groups: [
              ...(InitialEntity.groups || []),
              ...((NewEntity.groups &&
                NewEntity.groups.filter(groupId =>
                  InitialEntity.groups ? InitialEntity.groups.indexOf(groupId) === -1 : true
                )) ||
                [])
            ]
          })
        },
        processStrategy: (value, parent, key) => {
          if (parent.hasOwnProperty('owner_id') && !parent.hasOwnProperty('type_id')) {
            // the parent is a group
            value = Object.assign({}, value, {
              groups: value.groups ? value.groups : [parent.id]
            })
          }
          return value
        }
      }
    ),
    importType: new schema.Entity('importType'),
    meetingType: new schema.Entity('meetingType'),
    eventNotification: new schema.Entity('eventNotification'),
    eventNotificationRecipientType: new schema.Entity('eventNotificationRecipientTypes'),
    eventNotificationStatus: new schema.Entity('eventNotificationStatus'),
    eventNotificationType: new schema.Entity('eventNotificationTypes'),
    slot: new schema.Entity('slots'),
    staff: new schema.Entity('staff'),
    student: new schema.Entity('students'),
    subject: new schema.Entity('subjects'),
    user: new schema.Entity('user'),
    venue: new schema.Entity('venues')
  },
  helpers: {
    removeNestedData: function (input) {
      if (input instanceof Array) {
        return input.map(item => SchemaService.helpers.removeNestedData(item))
      } else if (input instanceof Object) {
        let output = {}
        let property
        for (property in input) {
          // if our input is falsey, just return it
          if (!input[property]) {
            output[property] = input[property]
            // if it contains data, return the contents of data, and strip out data itself
          } else if (input[property].data) {
            output[property] = SchemaService.helpers.removeNestedData(input[property].data)
          } else {
            output[property] = SchemaService.helpers.removeNestedData(input[property])
          }
        }
        return output
      }
      return input
    }
  }
}

SchemaService.schemas.booking.define({
  event_id: SchemaService.schemas.event,
  user_id: SchemaService.schemas.user
})

SchemaService.schemas.client.define({
  clientUser: [SchemaService.schemas.clientUser],
  staff: [SchemaService.schemas.staff],
  lessons: [SchemaService.schemas.lesson],
  organisers: [SchemaService.schemas.user],
  venues: [SchemaService.schemas.venue]
})

SchemaService.schemas.clientUser.define({
  client: SchemaService.schemas.client,
  role: SchemaService.schemas.role,
  user: SchemaService.schemas.user
})

SchemaService.schemas.groupLessonNote.define({
  event_id: SchemaService.schemas.event
})

SchemaService.schemas.comment.define({
  user: SchemaService.schemas.user,
  event_id: SchemaService.schemas.event
})

SchemaService.schemas.guestInvitation.define({
  students: [SchemaService.schemas.student],
  user: SchemaService.schemas.user
})

SchemaService.schemas.event.define({
  bookings: [SchemaService.schemas.booking],
  client: SchemaService.schemas.client,
  comments: [SchemaService.schemas.comment],
  groupLessonNotes: [SchemaService.schemas.groupLessonNote],
  invitations: [SchemaService.schemas.invitation],
  sessions: [SchemaService.schemas.session],
  groups: [SchemaService.schemas.group],
  meetingTypes: [SchemaService.schemas.meetingType]
})

SchemaService.schemas.group.define({
  groupLessonNotes: [SchemaService.schemas.groupLessonNote],
  user: SchemaService.schemas.user,
  event_id: SchemaService.schemas.event,
  lessons: [SchemaService.schemas.lesson],
  location: SchemaService.schemas.location,
  sessions: [SchemaService.schemas.session],
  slots: [SchemaService.schemas.slot]
})

SchemaService.schemas.guardian.define({
  client_id: SchemaService.schemas.client,
  students: [SchemaService.schemas.student],
  user: SchemaService.schemas.user
})

SchemaService.schemas.guardianStudent.define({
  student: SchemaService.schemas.student,
  guardian: SchemaService.schemas.guardian
})

SchemaService.schemas.invitation.define({
  event_id: SchemaService.schemas.event,
  user: SchemaService.schemas.user,
  invitationGroups: [SchemaService.schemas.invitationGroup],
  guardian: SchemaService.schemas.guardian
})

SchemaService.schemas.invitationGroup.define({
  invitation_id: SchemaService.schemas.invitation,
  lesson_id: SchemaService.schemas.lesson,
  student_id: SchemaService.schemas.student,
  student: SchemaService.schemas.student
})

SchemaService.schemas.location.define({
  venue_id: SchemaService.schemas.venue
})

SchemaService.schemas.lesson.define({
  client_id: SchemaService.schemas.client,
  groups: [SchemaService.schemas.group],
  students: [SchemaService.schemas.student],
  subject: SchemaService.schemas.subject,
  teacher: SchemaService.schemas.staff
})

SchemaService.schemas.session.define({
  event_id: SchemaService.schemas.event,
  groups: [SchemaService.schemas.group]
})

SchemaService.schemas.slot.define({
  event_id: SchemaService.schemas.event,
  group_id: SchemaService.schemas.group
})

SchemaService.schemas.staff.define({
  client_id: SchemaService.schemas.client,
  user_id: SchemaService.schemas.user
})

SchemaService.schemas.student.define({
  client_id: SchemaService.schemas.client,
  guardians: [SchemaService.schemas.guardian],
  guardianStudents: [SchemaService.schemas.guardianStudent]
})

SchemaService.schemas.subject.define({
  client_id: SchemaService.schemas.client,
  lessons: [SchemaService.schemas.lesson]
})

SchemaService.schemas.user.define({
  clients: [SchemaService.schemas.client],
  clientUser: [SchemaService.schemas.clientUser],
  rights: SchemaService.schemas.right
})

export default SchemaService
