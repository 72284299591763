import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const importTypes = {
  list: () => {
    return dispatch => {
      dispatch({ type: 'IMPORT_TYPES_LIST' })
      return APIRequest.get(dispatch, `importTypes`)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [
            SchemaService.schemas.importType
          ])

          return dispatch({
            type: 'IMPORT_TYPES_LIST_SUCCESS',
            importTypes: output.entities.importType
          })
        })
        .catch(err => {
          console.error('could not get import types', err.message)
          dispatch({ type: 'IMPORT_TYPES_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default importTypes
