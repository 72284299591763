export default function meetingTypes (state, action) {
  state = state || {
    error: null,
    fetchedAt: null,
    fetchingMeetingTypes: false,
    meetingTypes: {},
    meetingTypesList: []
  }

  switch (action.type) {
    case 'MEETING_TYPES_FETCHED':
      return Object.assign({}, state, {
        meetingTypes: Object.assign({}, state.meetingTypes, action.meetingTypes)
      })
    case 'MEETING_TYPES_LIST':
      return Object.assign({}, state, {
        fetchingMeetingTypes: true
      })
    case 'MEETING_TYPES_LIST_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        fetchingMeetingTypes: false
      })
    case 'MEETING_TYPES_LIST_SUCCESS':
      return Object.assign({}, state, {
        fetchedAt: new Date(),
        fetchingMeetingTypes: false,
        meetingTypes: Object.assign({}, state.meetingTypes, action.meetingTypes),
        meetingTypesList: action.meetingTypesList
      })

    default:
      return state
  }
}
