export default function guardianStudents (state, action) {
  state = state || {
    guardianStudents: {}
  }

  switch (action.type) {
    case 'GUARDIAN_STUDENTS_FETCHED':
      return Object.assign({}, state, {
        guardianStudents: Object.assign({}, state.guardianStudents, action.guardianStudents)
      })
    default:
      return state
  }
}
