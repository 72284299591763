import LoggerService from '../services/logger.service'
import { toast } from 'react-toastify'

const notifications = {
  error: (technicalMessage, humanMessage = technicalMessage, onClose = () => {}) => {
    return dispatch => {
      if (process.env.NODE_ENV !== 'development') {
        LoggerService.error(technicalMessage)
          .then(res => {
            return dispatch({
              type: 'ERROR_LOG_SUCCESS'
            })
          })
          .catch(res => {
            console.error('could not log errors', res.message)
            return dispatch({ type: 'ERROR_LOG_FAILURE', error: res.message })
          })
      }
      return new Promise((resolve, reject) => {
        toast.error(humanMessage, { onClose })
        resolve(
          dispatch({
            type: 'NOTIFICATION_ADD',
            message: humanMessage
          })
        )
      })
    }
  },
  warning: message => {
    return dispatch => {
      toast.warning(message)
      return dispatch({
        type: 'NOTIFICATION_ADD',
        message: message
      })
    }
  },
  information: message => {
    return dispatch => {
      toast.info(message)
      return dispatch({
        type: 'NOTIFICATION_ADD',
        message: message
      })
    }
  }
}

export default notifications
