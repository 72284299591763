export default function events (state, action) {
  state = state || {
    guestInvitations: {},
    error: null
  }

  switch (action.type) {
    case 'GUEST_INVITATION_CREATE_SUCCESS':
      return {
        ...state,
        guestInvitations: { ...state.guestInvitations, [action.guestInvitation.id]: action.guestInvitation }
      }

    case 'GUEST_INVITATION_DELETE_SUCCESS':
      let guestInvitations = { ...state.guestInvitations }

      delete guestInvitations[action.id]

      return { ...state, guestInvitations }

    case 'GUEST_INVITATION_LIST_SUCCESS':
      return {
        ...state,
        guestInvitations: action.guestInvitations
      }

    case 'GUEST_INVITATION_GET_SUCCESS':
      return {
        ...state,
        guestInvitations: { ...state.guestInvitations, [action.guestInvitation.id]: action.guestInvitation }
      }

    default:
      return state
  }
}
