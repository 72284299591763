import React from 'react'
import { Collapse } from 'reactstrap'
import PropTypes from 'prop-types'
import ToggleAngleIcon from '../../Atoms/ToggleAngleIcon'
import styles from './HelpItem.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import classnames from 'classnames'

class HelpItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: props.isOpen
    }
  }

  handleClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  render () {
    const { isOpen } = this.state
    const { children, title } = this.props

    return (
      <div className={styles.container}>
        <div className={classnames({ [styles.header]: true, [styles.selected]: isOpen })}>
          <div className='d-flex align-items-center'>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className={classnames({ [styles.icon]: true }, 'd-none d-sm-block')}
            />
            <h5 className={classnames({ [styles.title]: true, [styles.selected]: isOpen })}>{title}</h5>
          </div>
          <ToggleAngleIcon isActive={isOpen} onClick={() => this.handleClick()} />
        </div>

        <Collapse isOpen={isOpen}>
          <div className='mx-2 mx-sm-3 mb-4'>{children}</div>
        </Collapse>
      </div>
    )
  }
}

HelpItem.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired
}

HelpItem.defaultProps = {
  isOpen: true
}

export default HelpItem
