export default function guardians (state, action) {
  state = state || {
    guardians: {}
  }

  switch (action.type) {
    case 'GUARDIANS_FETCHED':
      return Object.assign({}, state, {
        guardians: Object.assign({}, state.guardians, action.guardians)
      })
    default:
      return state
  }
}
