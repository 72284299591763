import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const defaultIncludes = []

const staff = {
  list: (clientId, includes = defaultIncludes) => {
    return (dispatch) => {
      dispatch({ type: 'STAFF_LIST' })
      return APIRequest
        .get(dispatch, `clients/${clientId}/staff`, includes)
        .then((res) => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [SchemaService.schemas.staff])
          return dispatch({
            type: 'STAFF_LIST_SUCCESS',
            staff: output.entities.staff,
            staffList: output.result
          })
        })
        .catch((err) => {
          console.error('could not get staff list', err.message)
          dispatch({ type: 'STAFF_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default staff
