export default function staff (state, action) {
  state = state || {
    error: null,
    fetchedAt: null,
    fetchingStaff: false,
    staff: {},
    staffList: []
  }

  switch (action.type) {
    case 'STAFF_FETCHED':
      return Object.assign({}, state, {
        staff: Object.assign({}, state.staff, action.staff)
      })
    case 'STAFF_LIST':
      return Object.assign({}, state, {
        fetchingStaff: true
      })
    case 'STAFF_LIST_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        fetchingStaff: false
      })
    case 'STAFF_LIST_SUCCESS':
      return Object.assign({}, state, {
        fetchedAt: new Date(),
        fetchingStaff: false,
        staff: Object.assign({}, state.staff, action.staff),
        staffList: action.staffList
      })

    default:
      return state
  }
}
