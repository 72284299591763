const dotProp = require('dot-prop-immutable')

export default function events (state, action) {
  state = state || {
    events: {},
    fetchingEvents: true,
    fetchedAt: null,
    error: null,
    requestType: null,
    validation: null
  }

  switch (action.type) {
    case 'DISMISS_ERROR':
      return Object.assign({}, state, {
        error: null
      })

    // List
    case 'EVENT_LIST':
      return Object.assign({}, state, {
        requestType: 'LIST'
      })
    case 'EVENT_GET':
      return Object.assign({}, state, {
        fetchingEvents: true,
        requestType: 'GET'
      })
    case 'EVENT_CREATE':
      return Object.assign({}, state, {
        fetchingEvents: true
      })
    case 'EVENT_LIST_SUCCESS':
      return Object.assign({}, state, {
        fetchingEvents: false,
        fetchedAt: Date.now(),
        events: action.events,
        eventList: action.eventList
      })
    case 'EVENT_LIST_FAILURE':
    case 'EVENT_GET_FAILURE':
    case 'EVENT_CREATE_FAILURE':
      return Object.assign({}, state, {
        fetchingEvents: false,
        error: action.error
      })

    // Get
    case 'EVENT_GET_SUCCESS':
      // return Object.assign({}, state, {
      //   fetchingEvents: false,
      //   fetchedAt: Date.now(),
      //   events: Object.assign({}, state.events, action.events)
      // })

      const events = { ...state.events }
      const event = { ...state.events[action.eventId], ...action.events[action.eventId] }
      events[action.eventId] = event

      return Object.assign({}, state, {
        fetchingEvents: false,
        fetchedAt: Date.now(),
        events: events
      })

    // Create
    case 'EVENT_CREATE_SUCCESS':
      let newState = Object.assign({}, state, {
        fetchingEvents: false,
        fetchedAt: Date.now(),
        events: Object.assign({}, state.events, { [action.eventModel.id]: action.eventModel })
      })
      delete newState.events[0]
      return newState

    case 'EVENT_ADD':
      return Object.assign({}, state, {
        events: Object.assign(
          {},
          state.events,
          // add an empty event
          {
            0: {
              id: 0,
              // for the first iteration, we're only doing parent consultations (type 2)
              type_id: 2,
              meetingTypes: [],
              venue_id: null,
              max_bookings_per_student: 0,
              vc_auto_disconnect: false
            }
          }
        )
      })

    case 'EVENT_DELETE':
      let stateWithEventDeleted = Object.assign({}, state)
      stateWithEventDeleted.events = Object.assign({}, state.events)
      delete stateWithEventDeleted.events[action.groupId]
      return stateWithEventDeleted

    case 'EVENT_UPDATE':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.id]: Object.assign({}, state.events[action.id], {
            [action.property]: action.value
          })
        })
      })

    case 'EVENT_SESSION_ADDED':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            sessions: [...state.events[action.eventId].sessions, action.session.id]
          })
        })
      })

    case 'EVENT_SESSION_REMOVED':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            sessions: [...state.events[action.eventId].sessions.filter(sessionId => sessionId !== action.id)]
          })
        })
      })

    case 'EVENT_ADD_GROUP_SUCCESS':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            groups: [...state.events[action.eventId].groups, action.groupId]
          })
        })
      })

    case 'EVENT_REMOVE_GROUP_SUCCESS':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            groups: [...state.events[action.eventId].groups.filter(groupId => groupId !== action.groupId)]
          })
        })
      })

    case 'EVENT_ADD_NOTIFICATION':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            notifications: [...state.events[action.eventId].notifications, action.notification]
          })
        })
      })

    case 'EVENT_UPDATE_NOTIFICATION':
      const updatedNotificationIndex = state.events[action.eventId].notifications.findIndex(
        notification => notification.id === action.notification.id
      )

      return dotProp.set(
        state,
        `events.${action.eventId}.notifications.${updatedNotificationIndex}`,
        action.notification
      )

    case 'EVENT_ADD_INVITATION':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            invitations: [...state.events[action.eventId].invitations, action.invitationId]
          })
        })
      })

    case 'EVENT_REMOVE_INVITATION':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            invitations: state.events[action.eventId].invitations.filter(
              invitationId => invitationId !== action.invitationId
            )
          })
        })
      })

    case 'EVENT_ADD_BOOKING':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            bookings: [...state.events[action.eventId].bookings, action.bookingId]
          })
        })
      })

    case 'EVENT_VALIDATION':
      return Object.assign({}, state, {
        validation: action.validation
      })

    case 'EVENT_VALIDATION_CLEAR':
      return Object.assign({}, state, {
        validation: null
      })

    case 'EVENT_UPDATED':
      return Object.assign({}, state, {
        events: Object.assign({}, state.events, {
          [action.eventId]: Object.assign({}, state.events[action.eventId], {
            updatedAt: new Date()
          })
        })
      })

    default:
      return state
  }
}
