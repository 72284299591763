import GraphQLRequest from '../services/graphql.service'

const auditLog = {
  types: () => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `{
          auditLogTypes {
            id
            code
            description
          }
        }`
      }

      return graphQLRequest.send(query)
    }
  }
}

export default auditLog
