import React from 'react'
import PropTypes from 'prop-types'
import ImpersonatedUserBanner from '../Atoms/ImpersonatedUserBanner'
import Navigation from '../Molecules/Navigation'
import Footer from '../Molecules/Footer'
import Snackbar from '../Organisms/Snackbar'
import { connect } from 'react-redux'
import { display, notifications } from '../../actions'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ParentHelp } from '../Organisms/HelpModal/Articles'
import HelpModal from '../Organisms/HelpModal'

const Wrapper = props => {
  return (
    <div className='d-flex flex-column wrapper'>
      <Navigation
        onHelpTrigger={props.setHelpVisibility.bind(null, true)}
        handleNavToggle={props.setNavVisibility}
        isNavVisible={props.isNavVisible}
      />

      {props.user && props.user.impersonated && <ImpersonatedUserBanner user={props.user.user} />}

      <HelpModal
        title='School Booking Help'
        isOpen={props.isHelpModalVisible}
        content={<ParentHelp />}
        handleToggle={() => props.setHelpVisibility(!props.isHelpModalVisible)}
        action={
          <a href='/help/parents/parent-help.pdf' className='text-white text-decoration-none' download>
            <FontAwesomeIcon icon={faDownload} /> <small>Download PDF</small>
          </a>
        }
      />

      <main className='flex-grow-1 footer-spacing-ie11'>{props.children}</main>
      <Footer />
      <Snackbar notifications={props.notifications} handleNotificationRemove={props.handleNotificationRemove} />
    </div>
  )
}

Wrapper.propTypes = {
  notifications: PropTypes.array,
  handleNotificationRemove: PropTypes.func.isRequired,
  setNavVisibility: PropTypes.func.isRequired,
  isHelpModalVisible: PropTypes.bool.isRequired,
  isNavVisible: PropTypes.bool.isRequired,
  user: PropTypes.object,
  rights: PropTypes.object
}

Wrapper.defaultProps = {
  isNavBarVisible: true,
  isHelpModalVisible: false
}

function mapStateToProps (state) {
  return {
    clients: state.clients.clients,
    notifications: state.notifications.notifications,
    isHelpModalVisible: state.display.isHelpVisible,
    isNavVisible: state.display.isNavVisible,
    rights: state.rights.rights,
    user: state.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    handleNotificationRemove: notification => {
      return dispatch(notifications.remove(notification))
    },
    setHelpVisibility: isVisible => {
      return dispatch(display.setHelpVisibility(isVisible))
    },
    setNavVisibility: isVisible => {
      return dispatch(display.setNavVisibility(isVisible))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
