import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './HelpModal.module.scss'

const HelpModal = ({ action, isOpen, title, content, handleToggle }) => (
  <Modal className={classnames({ [styles.helpModal]: true })} size='xl' isOpen={isOpen} toggle={handleToggle}>
    <ModalHeader className={styles.modalHeader}>
      <div className='d-flex justify-content-between align-items-center'>
        <div className={styles.header}>
          <FontAwesomeIcon className='mr-2' icon={faQuestionCircle} />
          {title}
        </div>

        {action && <div className='action'>{action}</div>}
      </div>
    </ModalHeader>

    <ModalBody className='mb-3'>{content}</ModalBody>
  </Modal>
)

HelpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  action: PropTypes.object,
  handleToggle: PropTypes.func.isRequired
}

HelpModal.defaultProps = {
  action: null
}

export default HelpModal
