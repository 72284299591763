import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const defaultIncludes = []

const eventNotifications = {
  add: (eventId, payload, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'EVENT_NOTIFICATION_ADD' })

      return APIRequest.post(dispatch, `events/${eventId}/notifications`, payload, includes)
        .then(res => {
          const output = normalize(
            SchemaService.helpers.removeNestedData(res.body.data),
            SchemaService.schemas.eventNotification
          )

          return dispatch({
            type: 'EVENT_ADD_NOTIFICATION',
            eventId,
            notification: output.entities.eventNotification[output.result]
          })
        })
        .catch(err => {
          console.log(err)
          throw err
        })
    }
  },

  recipientTypes: (includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'EVENT_NOTIFICATION_RECIPIENT_TYPE_LIST' })

      return APIRequest.get(dispatch, 'events/notifications/recipientTypes', includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [
            SchemaService.schemas.eventNotificationRecipientType
          ])

          return dispatch({
            type: 'EVENT_NOTIFICATION_RECIPIENT_TYPE_LIST_SUCCESS',
            eventNotificationRecipientTypes: output.entities.eventNotificationRecipientTypes,
            eventNotificationRecipientTypesList: output.result
          })
        })
        .catch(err => {
          console.error('failed to get event notification recipient type list', err.message)
          dispatch({ type: 'EVENT_NOTIFICATION_RECIPIENT_TYPE_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  },

  status: (includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'EVENT_NOTIFICATION_STATUS_LIST' })

      return APIRequest.get(dispatch, 'events/notifications/status', includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [
            SchemaService.schemas.eventNotificationStatus
          ])

          return dispatch({
            type: 'EVENT_NOTIFICATION_STATUS_LIST_SUCCESS',
            eventNotificationStatus: output.entities.eventNotificationStatus,
            eventNotificationStatusList: output.result
          })
        })
        .catch(err => {
          console.error('failed to get event notification status list', err.message)
          dispatch({ type: 'EVENT_NOTIFICATION_STATUS_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  },

  types: (includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'EVENT_NOTIFICATION_TYPE_LIST' })

      return APIRequest.get(dispatch, 'events/notifications/types', includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [
            SchemaService.schemas.eventNotificationType
          ])

          return dispatch({
            type: 'EVENT_NOTIFICATION_TYPE_LIST_SUCCESS',
            eventNotificationTypes: output.entities.eventNotificationTypes,
            eventNotificationTypesList: output.result
          })
        })
        .catch(err => {
          console.error('failed to get event notification type list', err.message)
          dispatch({ type: 'EVENT_NOTIFICATION_TYPE_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  },

  update: (eventId, notificationId, payload, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'EVENT_NOTIFICATION_UPDATE' })

      return APIRequest.patch(dispatch, `events/${eventId}/notifications/${notificationId}`, payload, includes)
        .then(res => {
          const output = normalize(
            SchemaService.helpers.removeNestedData(res.body.data),
            SchemaService.schemas.eventNotification
          )

          return dispatch({
            type: 'EVENT_UPDATE_NOTIFICATION',
            eventId,
            notification: output.entities.eventNotification[output.result]
          })
        })
        .catch(err => {
          console.log(err)
          throw err
        })
    }
  }
}

export default eventNotifications
