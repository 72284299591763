import React, { Component } from 'react'
import Loading from './Loading'
import qs from 'qs'
import { Redirect } from 'react-router-dom'
import Wrapper from './_Wrapper'
import { withRouter } from 'react-router'

// redux stuff
import { connect } from 'react-redux'
import { notifications, user } from '../../actions'

export default function withoutAuth (ProtectedComponent) {
  class Unauthenticated extends Component {
    constructor (props) {
      super(props)
      this.state = {
        isLoading: true
      }
    }

    componentWillMount () {
      let token = localStorage.getItem('apiToken')

      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

      if (params.token && token) {
        token = params.token
        this.props.clearUser().then(res => {
          localStorage.setItem('apiToken', token)
          this.props.history.push(this.props.location.pathname)
          this.setState({
            isLoading: false
          })
        })
      }
    }

    componentDidMount () {
      const token = localStorage.getItem('apiToken')
      if ((!this.props.user || !this.props.user.token) && token) {
        this.props.authByToken(token).then(res => {
          this.setState({
            isLoading: false
          })
        })
      } else {
        this.setState({
          isLoading: false
        })
      }
    }

    render () {
      return (
        <Wrapper>
          {this.state.isLoading ? (
            <Loading />
          ) : !this.props.user || !this.props.user.token ? (
            <ProtectedComponent {...this.props} />
          ) : (
            <Redirect to='/' />
          )}
        </Wrapper>
      )
    }
  }

  function mapStateToProps (state) {
    return {
      user: state.user
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      authUser: (identifier, password) => {
        return dispatch(user.auth(identifier, password))
          .then(res => {
            return dispatch(user.setToken(res.body.data.token))
          })
          .then(token => {
            return dispatch(user.getFromToken(token))
          })
          .then(res => {
            return dispatch(user.set(res))
          })
          .catch(err => {
            return dispatch(notifications.error(err.message, 'Authentication failed, please try again')).then(() => {
              return dispatch(user.logout())
            })
          })
      },
      authByToken: token => {
        return dispatch(user.setToken(token))
          .then(token => {
            return dispatch(user.getFromToken(token))
          })
          .then(res => {
            return dispatch(user.set(res))
          })
          .catch(err => {
            return dispatch(notifications.error(err.message, 'Authentication failed, please try again')).then(() => {
              return dispatch(user.logout())
            })
          })
      },
      clearUser: () => {
        return dispatch(user.logout())
      }
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(withRouter(Unauthenticated))
}
