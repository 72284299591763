export default function venues (state, action) {
  state = state || {
    venues: {},
    fetching: true,
    fetchedAt: null,
    error: null
  }

  switch (action.type) {
    // List
    case 'VENUE_LIST_SUCCESS':
      return Object.assign({}, state, {
        fetching: false,
        fetchedAt: Date.now(),
        venues: Object.assign({}, state.venues, action.venues)
      })

    default:
      return state
  }
}
