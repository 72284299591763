export default function misIntegrator (state, action) {
  state = state || {
    error: null,
    isSetup: false
  }

  switch (action.type) {
    case 'IS_MIS_INTEGRATOR_SETUP_SUCCESS':
      return Object.assign({}, state, {
        isSetup: action.isSetup
      })

    case 'IS_MIS_INTEGRATOR_SETUP_FAILURE':
      return Object.assign({}, state, {
        error: action.error
      })

    case 'IMPORT_MIS_INTEGRATOR_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        importing: false
      })

    case 'IMPORT_MIS_INTEGRATOR_QUEUED':
      return state

    default:
      return state
  }
}
