import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const meetingTypes = {
  list: () => {
    return dispatch => {
      dispatch({ type: 'MEETING_TYPES' })
      return APIRequest.get(dispatch, `meetingTypes`)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [
            SchemaService.schemas.meetingType
          ])

          return dispatch({
            type: 'MEETING_TYPES_LIST_SUCCESS',
            meetingTypes: output.entities.meetingType,
            meetingTypesList: output.result
          })
        })
        .catch(err => {
          console.error('could not get meeting types', err.message)
          dispatch({ type: 'MEETING_TYPES_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default meetingTypes
