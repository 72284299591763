export default function eventNotificationStatus (state, action) {
  state = state || {
    error: null,
    eventNotificationStatus: {},
    eventNotificationStatusList: []
  }

  switch (action.type) {
    case 'EVENT_NOTIFICATION_STATUS_LIST':
    case 'EVENT_NOTIFICATION_STATUS_LIST_FAILURE':
      return Object.assign({}, state, {
        error: action.error
      })
    case 'EVENT_NOTIFICATION_STATUS_LIST_SUCCESS':
      return Object.assign({}, state, {
        eventNotificationStatus: Object.assign({}, state.eventNotificationStatus, action.eventNotificationStatus),
        eventNotificationStatusList: action.eventNotificationStatusList
      })

    default:
      return state
  }
}
