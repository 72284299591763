import React from 'react'
import { ToastContainer } from 'react-toastify'

const Snackbar = () => (
  <ToastContainer />
)

Snackbar.propTypes = {
}

export default Snackbar
