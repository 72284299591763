import APIRequest from '../services/api.service'

const misIntegrator = {
  update: (clientId, forceImport) => {
    return dispatch => {
      dispatch({ type: 'IMPORT_MIS_INTEGRATOR' })
      return APIRequest.post(dispatch, `clients/${clientId}/misImport`, {
        forceImport
      })
        .then(res => {
          return dispatch({
            type: 'IMPORT_MIS_INTEGRATOR_QUEUED'
          })
        })
        .catch(err => {
          console.error('failed to import', err.message)
          dispatch({ type: 'IMPORT_MIS_INTEGRATOR_FAILURE', error: err.message })
          throw err
        })
    }
  },

  isMISIntegratorSetup: clientId => {
    return dispatch => {
      dispatch({ type: 'IS_MIS_INTEGRATOR_SETUP' })
      return APIRequest.get(dispatch, `clients/${clientId}/isMISIntegratorSetup`)
        .then(res => {
          return dispatch({
            type: 'IS_MIS_INTEGRATOR_SETUP_SUCCESS',
            isSetup: res.body.data.is_setup
          })
        })
        .catch(err => {
          console.error('could not get MIS integrator credentials', err.message)
          dispatch({ type: 'IS_MIS_INTEGRATOR_SETUP_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default misIntegrator
