import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const guestInvitations = {
  create: (eventId, payload) => {
    return dispatch => {
      dispatch({ type: 'GUEST_INVITATION_CREATE' })

      return APIRequest.post(dispatch, `events/${eventId}/guest`, payload)
        .then(res => {
          const output = normalize(
            SchemaService.helpers.removeNestedData(res.body.data),
            SchemaService.schemas.guestInvitation
          )

          return dispatch({
            type: 'GUEST_INVITATION_CREATE_SUCCESS',
            guestInvitation: output.entities.guestInvitations[output.result]
          })
        })
        .catch(err => {
          dispatch({ type: 'GUEST_INVITATION_FAILURE', error: err.message })
          throw err
        })
    }
  },

  delete: guestInvitationId => {
    return dispatch => {
      dispatch({ type: 'GUEST_INVITATION_DELETE' })
      return APIRequest.delete(dispatch, `guestInvitation/${guestInvitationId}`)
        .then(res =>
          dispatch({
            type: 'GUEST_INVITATION_DELETE_SUCCESS',
            id: guestInvitationId
          })
        )
        .catch(err => {
          dispatch({ type: 'GUEST_INVITATION_DELETE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  resend: guestInvitationId => {
    return dispatch => {
      dispatch({ type: 'GUEST_INVITATION_RESEND' })
      return APIRequest.post(dispatch, `guestInvitation/${guestInvitationId}/resend`)
        .then(res =>
          dispatch({
            type: 'GUEST_INVITATION_RESEND_SUCCESS'
          })
        )
        .catch(err => {
          dispatch({ type: 'GUEST_INVITATION_RESEND_FAILURE', error: err.message })
          throw err
        })
    }
  },

  list: eventId => {
    return dispatch => {
      dispatch({ type: 'GUEST_INVITATION_LIST' })

      return APIRequest.get(dispatch, `events/${eventId}/guest`)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [
            SchemaService.schemas.guestInvitation
          ])

          return dispatch({
            type: 'GUEST_INVITATION_LIST_SUCCESS',
            guestInvitations: output.entities.guestInvitations || {}
          })
        })
        .catch(err => {
          dispatch({ type: 'GUEST_INVITATION_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  },

  /**
   * Data required for the guest invitation page
   * This is a copy of the events get action with extraneous data removed
   */
  itinerary: (eventId, token) => {
    return dispatch => {
      dispatch({ type: 'EVENT_GET' })
      return APIRequest.get(dispatch, 'events/' + eventId + '/guest/' + token)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.event)

          if (output.entities.clients) {
            dispatch({
              type: 'CLIENT_FETCH_SUCCESS',
              clients: output.entities.clients
            })
          }

          if (output.entities.invitations) {
            dispatch({
              type: 'INVITATIONS_FETCHED',
              invitations: output.entities.invitations
            })
          }

          if (output.entities.invitationGroups) {
            dispatch({
              type: 'INVITATION_GROUPS_FETCHED',
              invitationGroups: output.entities.invitationGroups
            })
          }

          if (output.entities.groupLessonNotes) {
            dispatch({
              type: 'GROUP_LESSON_NOTES_FETCHED',
              groupLessonNotes: output.entities.groupLessonNotes
            })
          }

          if (output.entities.students) {
            dispatch({
              type: 'STUDENTS_FETCHED',
              students: output.entities.students
            })
          }

          if (output.entities.subjects) {
            dispatch({
              type: 'SUBJECTS_FETCHED',
              subjects: output.entities.subjects
            })
          }

          if (output.entities.lessons) {
            dispatch({
              type: 'LESSONS_FETCHED',
              lessonList: output.entities.lessons
            })
          }

          if (output.entities.sessions) {
            dispatch({
              type: 'SESSIONS_FETCHED',
              sessions: output.entities.sessions
            })
          }

          if (output.entities.groups) {
            dispatch({
              type: 'GROUPS_FETCHED',
              groups: output.entities.groups
            })
          }

          if (output.entities.slots) {
            dispatch({
              type: 'SLOTS_FETCHED',
              slots: output.entities.slots
            })
          }

          if (output.entities.bookings) {
            dispatch({
              type: 'BOOKINGS_FETCHED',
              bookings: output.entities.bookings
            })
          }

          if (output.entities.user) {
            dispatch({
              type: 'USERS_FETCHED',
              users: output.entities.user
            })
          }

          return dispatch({
            type: 'EVENT_GET_SUCCESS',
            events: output.entities.events,
            eventId: output.result
          })
        })
        .catch(err => {
          console.error('failed to get event', err)
          dispatch({ type: 'EVENT_GET_FAILURE', error: err.message })
          throw err
        })
    }
  },

  get: token => {
    return dispatch => {
      dispatch({ type: 'GUEST_INVITATION_GET' })
      return APIRequest.get(dispatch, 'guest/' + token).then(res => {
        const output = normalize(
          SchemaService.helpers.removeNestedData(res.body.data),
          SchemaService.schemas.guestInvitation
        )

        if (output.entities.user) {
          dispatch({
            type: 'USERS_FETCHED',
            users: output.entities.user
          })
        }

        return dispatch({
          type: 'GUEST_INVITATION_GET_SUCCESS',
          guestInvitation: output.entities.guestInvitations[output.result]
        })
      })
    }
  }
}

export default guestInvitations
