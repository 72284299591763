import APIRequest from '../services/api.service'

const groupLessonNotes = {
  delete: groupLessonNoteId => {
    return dispatch => {
      dispatch({ type: 'GROUP_LESSON_NOTE_DELETE' })

      return APIRequest.delete(dispatch, `groupLessonNotes/${groupLessonNoteId}`)
        .then(res => {
          dispatch({
            type: 'GROUP_LESSON_NOTE_DELETED',
            id: groupLessonNoteId,
            groupId: res.body.data.group_id
          })

          return dispatch({
            type: 'GROUP_LESSON_NOTE_DELETE_SUCCESS',
            id: groupLessonNoteId
          })
        })

        .catch(err => {
          console.error('failed to delete group lesson note', err.message)
          dispatch({ type: 'GROUP_LESSON_NOTE_UPDATE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  create: (groupId, lessonId, note, includes = []) => {
    return dispatch => {
      dispatch({ type: 'GROUP_LESSON_NOTE_CREATE' })

      return APIRequest.patch(dispatch, `groups/${groupId}/lessons/${lessonId}/note`, { note }, includes)
        .then(res => {
          dispatch({
            type: 'GROUP_LESSON_NOTE_CREATE_SUCCESS',
            id: res.body.data.id,
            note: res.body.data
          })

          return dispatch({
            type: 'GROUP_LESSON_NOTE_CREATED',
            id: res.body.data.id,
            groupId
          })
        })
        .catch(err => {
          console.error('failed to add group lesson note', err.message)
          dispatch({ type: 'GROUP_LESSON_NOTE_CREATE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  update: (groupId, lessonId, note, includes = []) => {
    return dispatch => {
      dispatch({ type: 'GROUP_LESSON_NOTE_UPDATE' })

      return APIRequest.patch(dispatch, `groups/${groupId}/lessons/${lessonId}/note`, { note }, includes)
        .then(res => {
          return dispatch({
            type: 'GROUP_LESSON_NOTE_UPDATE_SUCCESS',
            note: res.body.data
          })
        })
        .catch(err => {
          console.error('failed to update group lesson note', err.message)
          dispatch({ type: 'GROUP_LESSON_NOTE_UPDATE_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default groupLessonNotes
