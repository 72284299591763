import APIRequest from '../services/api.service'
import GraphQLRequest from '../services/graphql.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const graphQLRequest = new GraphQLRequest()
const defaultIncludes = []

const bookings = {
  show: bookingId => {
    return dispatch => {
      const query = {
        query: 'query($id: ID!) { itineraryPreview(id: $id) { html }}',
        variables: { id: bookingId }
      }

      return graphQLRequest.send(query)
    }
  },

  email: bookingId => {
    return dispatch => {
      const query = {
        query: 'mutation ($input: SendItineraryInput!) {sendItinerary(input: $input) { success }}',
        variables: { input: { booking_id: bookingId } }
      }

      return graphQLRequest.send(query)
    }
  },

  emails: (eventId, bookingIds) => {
    return dispatch => {
      const query = {
        query: 'mutation($input: SendItinerariesInput!) {sendItineraries(input: $input) { success }}',
        variables: { input: { event_id: eventId, booking_ids: bookingIds } }
      }

      return graphQLRequest.send(query)
    }
  },

  /**
   * Just attendance atm but additional fields can be added here in the future if required
   */
  updateBookingSlot: (bookingId, bookingSlotId, qty, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'BOOKING_SLOT_UPDATE', bookingSlotId })
      return APIRequest.patch(
        dispatch,
        `bookings/${bookingId}/bookingSlot/${bookingSlotId}`,
        {
          attended_quantity: qty
        },
        includes
      )
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.booking)
          return dispatch({
            type: 'BOOKING_SLOT_UPDATE_SUCCESS',
            bookingId: bookingId,
            bookingSlots: output.entities.bookings[output.result].bookingSlots
          })
        })
        .catch(err => {
          dispatch({ type: 'BOOKING_SLOT_UPDATE_FAILURE', bookingSlotId: bookingSlotId, error: err.message })
          throw err
        })
    }
  },

  addSlot: (bookingId, slotId, studentId, lessonId, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'BOOK_SLOT', slotId: slotId })
      return APIRequest.post(
        dispatch,
        `bookings/${bookingId}/slots`,
        {
          slot: slotId,
          lesson: lessonId,
          student: studentId
        },
        includes
      )
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.booking)

          return dispatch({
            type: 'BOOK_SLOT_SUCCESS',
            bookingId: bookingId,
            bookingSlots: output.entities.bookings[output.result].bookingSlots
          })
        })
        .catch(err => {
          dispatch({ type: 'BOOK_SLOT_FAILURE', slotId: slotId, error: err.message })
          throw err
        })
    }
  },
  removeSlot: (bookingId, slotId, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'UNBOOK_SLOT', slotId: slotId })
      return APIRequest.delete(dispatch, `bookings/${bookingId}/slots/${slotId}`, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.booking)

          dispatch({
            type: 'SLOT_REMOVE_BOOKING_SUCCESS',
            slotId,
            bookingId
          })

          return dispatch({
            type: 'UNBOOK_SLOT_SUCCESS',
            bookingId,
            bookingSlots: output.entities.bookings[output.result].bookingSlots
          })
        })
        .catch(err => {
          dispatch({ type: 'UNBOOK_SLOT_FAILURE', slotId: slotId, error: err.message })
          throw err
        })
    }
  },
  create: (eventId, userId, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'BOOKING_CREATE' })
      return APIRequest.post(
        dispatch,
        `events/${eventId}/bookings`,
        {
          user_id: userId
        },
        includes
      )
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.booking)
          dispatch({
            type: 'BOOKING_CREATE_SUCCESS',
            booking: output.entities.bookings[output.result]
          })
          return dispatch({
            type: 'EVENT_ADD_BOOKING',
            bookingId: output.result,
            eventId: eventId
          })
        })
        .catch(err => {
          dispatch({ type: 'BOOKING_CREATE_FAILURE', eventId: eventId, error: err.message })
          throw err
        })
    }
  }
}

export default bookings
