const dotProp = require('dot-prop-immutable')

const initialState = {
  error: null,
  comments: {}
}

export default function comments (state = initialState, action) {
  switch (action.type) {
    case 'COMMENTS_FETCHED':
      return { ...state, comments: { ...state.comments, ...action.comments } }
    case 'COMMENT_CREATE_SUCCESS':
      return { ...state, comments: { ...state.comments, [action.comment.id]: action.comment } }
    case 'COMMENT_DELETE_SUCCESS':
      return dotProp.delete(state, `comments.${action.commentId}`)
    case 'COMMENT_UPDATE_SUCCESS':
      return { ...state, comments: { ...state.comments, [action.comment.id]: action.comment } }
    default:
      return state
  }
}
