export default function user (state, action) {
  state = state || {
    hasFailedAuthentication: false,
    clients: [],
    clientUsers: {},
    fetchingUser: false,
    fetchedUserAt: null,
    impersonated: null,
    isLoggingIn: false,
    roles: {},
    impersonatorToken: null,
    token: null,
    user: null,
    users: {}
  }

  switch (action.type) {
    case 'USER_LOGIN':
      return Object.assign({}, state, {
        isLoggingIn: true
      })

    case 'USER_LOGIN_SUCCESS':
      return Object.assign({}, state, {
        hasFailedAuthentication: false,
        isLoggingIn: false,
        roles: Object.assign({}, state.roles, action.roles),
        clientUsers: action.clientUsers,
        user: action.newUser,
        impersonated: localStorage.getItem('impersonated'),
        impersonatorToken: localStorage.getItem('impersonatorToken')
      })

    case 'USER_TOKEN_SET':
      return Object.assign({}, state, {
        token: action.token
      })

    case 'USER_IMPERSONATE_SUCCESS': {
      localStorage.setItem('apiToken', action.token)
      localStorage.setItem('impersonated', true)
      localStorage.setItem('impersonatorToken', state.token)

      return Object.assign({}, state, {
        token: action.token,
        impersonated: true,
        impersonatorToken: state.token
      })
    }

    case 'USER_IMPERSONATE_STOP': {
      const impersonatorToken = localStorage.getItem('impersonatorToken')
      localStorage.removeItem('impersonated')
      localStorage.removeItem('impersonatorToken')
      localStorage.setItem('apiToken', impersonatorToken)

      return Object.assign({}, state, {
        token: impersonatorToken,
        impersonated: false,
        impersonatorToken: null
      })
    }

    case 'USER_LOGOUT':
      return Object.assign({}, state, {
        token: null,
        hasFailedAuthentication: true,
        isLoggingIn: false,
        user: null
      })
    case 'USER_UPDATE':
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, action.userObject)
      })
    case 'USER_EMAIL_UPDATED':
      return {
        ...state,
        users: {
          ...state.users,
          ...{ [action.user.id]: { ...state.users[action.user.id], ...action.user } }
        }
      }
    case 'USER_GET':
      return Object.assign({}, state, {
        fetchingUser: true
      })
    case 'USER_GET_SUCCESS':
      return Object.assign({}, state, {
        user: action.user,
        clients: action.clients || state.clients,
        fetchingUser: false,
        fetchedUserAt: Date.now()
      })
    case 'USER_IMPERSONATE':
      return Object.assign({}, state, {
        impersonated: action.userId
      })
    case 'USERS_FETCHED':
      return Object.assign({}, state, {
        users: Object.assign({}, state.users, action.users)
      })
    default:
      return state
  }
}
