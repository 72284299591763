import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const defaultIncludes = []

const locations = {
  list: (eventId, includes = defaultIncludes) => {
    return (dispatch) => {
      dispatch({ type: 'LOCATION_LIST' })
      return APIRequest
        .get(dispatch, `events/${eventId}/locations`, includes)
        .then((res) => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [SchemaService.schemas.location])
          return dispatch({
            type: 'LOCATION_LIST_SUCCESS',
            locations: output.entities.location,
            locationsList: output.result
          })
        })
        .catch((err) => {
          console.error(err)
          dispatch({ type: 'LOCATION_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default locations
