const initialState = {
  error: null,
  groupLessonNotes: {}
}

export default function groupLessonNotes (state = initialState, action) {
  switch (action.type) {
    case 'GROUP_LESSON_NOTES_FETCHED':
      return { ...state, groupLessonNotes: { ...state.groupLessonNotes, ...action.groupLessonNotes } }

    case 'GROUP_LESSON_NOTE_DELETE_SUCCESS':
      const { [action.id]: deleted, ...newGroupLessonNotes } = state.groupLessonNotes

      return {
        ...state,
        groupLessonNotes: newGroupLessonNotes
      }

    case 'GROUP_LESSON_NOTE_UPDATE_SUCCESS':
      return {
        ...state,
        groupLessonNotes: { ...state.groupLessonNotes, [action.note.id]: action.note }
      }

    case 'GROUP_LESSON_NOTE_CREATE_SUCCESS':
      return {
        ...state,
        groupLessonNotes: { ...state.groupLessonNotes, [action.note.id]: action.note }
      }

    default:
      return state
  }
}
