export default function students (state, action) {
  state = state || {
    students: {}
  }

  switch (action.type) {
    case 'STUDENTS_FETCHED':
      return Object.assign({}, state, {
        students: Object.assign({}, state.students, action.students)
      })
    case 'STUDENT_GET_SUCCESS':
      return Object.assign({}, state, {
        students: Object.assign({}, state.students, {
          [action.studentList]: Object.assign({}, state.students[action.studentList], action.students[action.studentList])
        })
      })
    default:
      return state
  }
}
