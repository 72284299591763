import React, { Component } from 'react'
import { Button, Container } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import Loading from '../Loading'
import WithoutAuth from '../_WithoutAuth'
import qs from 'qs'

// redux stuff
import { connect } from 'react-redux'
import { notifications, user } from '../../../actions'
import styles from './Login.module.scss'

class Login extends Component {
  componentWillMount () {
    if (this.props.user.user) {
      this.props.clearUser()
    }
  }

  componentDidMount () {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    if (params.sessionExpired && params.sessionExpired === 'true') {
      this.props.history.push('/login')
      this.props.showSessionExpiredMessage()
    }
  }

  render () {
    const storedToken = localStorage.getItem('apiToken')
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    if (params.token) {
      localStorage.setItem('apiToken', params.token)
      return <Redirect to={this.props.location.pathname} />
    } else if (storedToken) {
      this.props.authByToken(storedToken)
    } else if (this.props.user.isLoggingIn) {
      return <Loading notifications={this.props.notifications} handleNotificationRemove={this.props.handleNotificationRemove} />
    }

    return (
      <div className={styles.login}>
        <Container>
          <img className='mb-2' src='school-booking-logo.svg' alt='School Booking Logo' width='150' />
          <h1>SchoolBooking has moved!</h1>
          <h3>SchoolBooking has moved to a new parents portal. Click the button below to be redirected to the new portal.</h3>
          <Button color='primary' href='https://parents.events.schoolbooking.com'>
            Go To Parents Portal
          </Button>
        </Container>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    router: state.router,
    user: state.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    authUser: (identifier, password) => {
      return dispatch(user.auth(identifier, password))
        .then(res => {
          return dispatch(user.setToken(res.body.data.token))
        })
        .then(token => {
          return dispatch(user.getFromToken(token))
        })
        .then(res => {
          return dispatch(user.set(res))
        })
        .catch(err => {
          return dispatch(notifications.error(err.message, 'Authentication failed, please check your credentials and try again')).then(() => {
            return dispatch(user.logout())
          })
        })
    },

    authByToken: token => {
      return dispatch(user.setToken(token))
        .then(token => {
          return dispatch(user.getFromToken(token))
        })
        .then(res => {
          return dispatch(user.set(res))
        })
        .catch(err => {
          return dispatch(notifications.error(err.message, 'Authentication failed, please check your credentials and try again')).then(() => {
            return dispatch(user.logout())
          })
        })
    },

    clearUser: () => {
      return dispatch(user.logout())
    },

    showSessionExpiredMessage: () => {
      return dispatch(notifications.information('Your session has expired. Please Login.'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithoutAuth(Login)))
