export default function invitationGroups (state, action) {
  state = state || {
    invitationGroups: {}
  }

  switch (action.type) {
    case 'INVITATION_GROUPS_FETCHED':
      return Object.assign({}, state, {
        invitationGroups: action.invitationGroups
      })

    case 'INVITATION_GROUPS_UPDATED':
      return Object.assign({}, state, {
        invitationGroups: Object.assign({}, state.invitationGroups, action.invitationGroups)
      })

    default:
      return state
  }
}
