
const display = {
  setHelpVisibility: (isVisible = false) => {
    return (dispatch) => {
      return dispatch({ type: 'SET_HELP_VISIBILITY', isVisible })
    }
  },
  setNavVisibility: (isVisible = false) => {
    return (dispatch) => {
      return dispatch({ type: 'SET_NAV_VISIBILITY', isVisible })
    }
  }
}

export default display
