import APIRequest from '../services/api.service'

const log = {
  show: (client, loggableType, logTypeId, loggableId = null, limit = null) => {
    return dispatch => {
      dispatch({ type: 'LOG' })
      return APIRequest.post(dispatch, `clients/${client.id}/log`, {
        loggable_type: loggableType,
        log_type_id: logTypeId,
        loggable_id: loggableId,
        limit
      })
        .then(res => {
          return dispatch({
            type: 'LOG_SUCCESS',
            log: res.body.data
          })
        })
        .catch(err => {
          console.error('could not get log', err.message)
          dispatch({ type: 'LOG_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default log
