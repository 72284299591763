export default function display (state, action) {
  state = state || {
    isHelpVisible: false,
    isNavVisible: false
  }

  switch (action.type) {
    case 'SET_HELP_VISIBILITY':
      return Object.assign({}, state, {
        isHelpVisible: action.isVisible
      })

    case 'SET_NAV_VISIBILITY':
      return Object.assign({}, state, {
        isNavVisible: action.isVisible
      })

    default:
      return state
  }
}
