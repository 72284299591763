import APIRequest from '../services/api.service'
import GraphQLRequest from '../services/graphql.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'

const graphQLRequest = new GraphQLRequest()
const defaultIncludes = []

const invitations = {
  deleteInvitationGroupsForStudent: (invitation, studentId, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({
        type: 'EVENT_REMOVE_INVITATION_GROUPS_FOR_STUDENT',
        invitationId: invitation.id,
        studentId: studentId
      })
      return APIRequest.delete(dispatch, `invitations/${invitation.id}/students/${studentId}`, includes)
        .then(res => {
          let output
          if (res.text !== '[]') {
            output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.invitation)
          }

          if (output) {
            return dispatch({
              type: 'INVITATION_GET_SUCCESS',
              invitation: output.entities.invitations[output.result]
            })
          }
          dispatch({
            type: 'EVENT_REMOVE_INVITATION',
            invitationId: invitation.id,
            eventId: invitation.event_id
          })

          return dispatch({
            type: 'INVITATION_DELETE_SUCCESS',
            invitationId: invitation.id
          })
        })
        .catch(err => {
          console.error(err)
          dispatch({ type: 'EVENT_REMOVE_INVITATION_GROUPS_FOR_STUDENT_FAILURE', error: err.message })
          throw err
        })
    }
  },

  show: invitationId => {
    return dispatch => {
      const query = {
        query: 'query($id: ID!) { invitationPreview(id: $id) { html }}',
        variables: { id: invitationId }
      }

      return graphQLRequest.send(query)
    }
  },

  // show: (invitationId, includes = defaultIncludes) => {
  //   return dispatch => {
  //     dispatch({ type: 'INVITATION_GET' })
  //     return APIRequest.get(dispatch, `invitations/${invitationId}`, includes).then(res => {
  //       const output = normalize(
  //         SchemaService.helpers.removeNestedData(res.body.data),
  //         SchemaService.schemas.invitation
  //       )
  //       dispatch({
  //         type: 'INVITATION_GET_SUCCESS',
  //         invitation: output.entities.invitations[output.result]
  //       })
  //     })
  //   }
  // },

  email: invitationId => {
    return dispatch => {
      const query = {
        query: 'mutation ($input: SendInvitationInput!) {sendInvitation(input: $input) { success }}',
        variables: { input: { invitation_id: invitationId } }
      }

      return graphQLRequest.send(query)
    }
  },

  emails: (eventId, invitationIds) => {
    return dispatch => {
      const query = {
        query: 'mutation ($input: SendInvitationsInput!) {sendInvitations(input: $input) { success }}',
        variables: { input: { event_id: eventId, invitation_ids: invitationIds } }
      }

      return graphQLRequest.send(query)
    }
  },

  // emails: invitationIds => {
  //   return dispatch => {
  //     dispatch({ type: 'INVITATION_EMAILS' })
  //     return APIRequest.post(dispatch, `emails/invitations`, { invitation_ids: invitationIds }).then(res => {
  //       const output = res.body.message
  //       dispatch({
  //         type: 'INVITATION_EMAILS_SUCCESS',
  //         invitation: output,
  //         invitationIds
  //       })
  //     })
  //   }
  // },

  // email: invitationId => {
  //   return dispatch => {
  //     dispatch({ type: 'INVITATION_EMAIL' })
  //     return APIRequest.post(dispatch, `invitations/${invitationId}`).then(res => {
  //       const output = res.body.message
  //       dispatch({
  //         type: 'INVITATION_EMAIL_SUCCESS',
  //         invitation: output,
  //         invitationId
  //       })
  //     })
  //   }
  // },

  update: (invitationModel, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'INVITATION_UPDATE' })
      return APIRequest.patch(dispatch, `invitations/${invitationModel.id}`, invitationModel, includes)
        .then(res => {
          const output = normalize(
            SchemaService.helpers.removeNestedData(res.body.data),
            SchemaService.schemas.invitation
          )
          return dispatch({
            type: 'INVITATION_UPDATE_SUCCESS',
            invitation: output.entities.invitations[invitationModel.id]
          })
        })
        .catch(err => {
          dispatch({ type: 'INVITATION_UPDATE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  // emails: invitationIds => {
  //   return dispatch => {
  //     dispatch({ type: 'INVITATION_EMAILS' })
  //     return APIRequest.post(dispatch, `emails/invitations`, { invitation_ids: invitationIds }).then(res => {
  //       const output = res.body.message
  //       dispatch({
  //         type: 'INVITATION_EMAILS_SUCCESS',
  //         invitation: output,
  //         invitationIds
  //       })
  //     })
  //   }
  // },

  addGuardian: (eventId, studentId, userId, guardianId, includes = ['invitationGroups']) => {
    return dispatch => {
      dispatch({ type: 'INVITATION_ADD' })
      return APIRequest.post(
        dispatch,
        `events/${eventId}/students/${studentId}/invitations`,
        {
          user_id: userId,
          guardian_id: guardianId
        },
        includes
      )
        .then(res => {
          const output = normalize(
            SchemaService.helpers.removeNestedData(res.body.data),
            SchemaService.schemas.invitation
          )
          if (output.entities.invitationGroups) {
            dispatch({
              type: 'INVITATION_GROUPS_UPDATED',
              invitationGroups: output.entities.invitationGroups
            })
          }
          const response = dispatch({
            type: 'INVITATION_GET_SUCCESS',
            invitation: output.entities.invitations[output.result]
          })

          dispatch({
            type: 'EVENT_ADD_INVITATION',
            eventId: eventId,
            invitationId: output.result
          })

          return response
        })
        .catch(err => {
          dispatch({ type: 'INVITATION_ADD_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default invitations
