import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'
import GraphQLRequest from '../services/graphql.service'

const defaultIncludes = []

const sessions = {
  add: (eventId, session, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'SESSION_ADD' })
      return APIRequest.post(dispatch, `events/${eventId}/sessions`, session, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.session)
          const session = output.entities.sessions[output.result]
          dispatch({
            type: 'SESSION_ADD_SUCCESS',
            session
          })
          return dispatch({
            type: 'EVENT_SESSION_ADDED',
            eventId,
            session
          })
        })
        .catch(err => {
          dispatch({ type: 'SESSION_ADD_FAILURE', error: err.response.body.error.message })
          throw err
        })
    }
  },

  save: (session, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'SESSION_SAVE' })
      return APIRequest.patch(dispatch, `sessions/${session.id}`, session, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.session)
          return dispatch({
            type: 'SESSION_SAVE_SUCCESS',
            session: output.entities.sessions[output.result]
          })
        })
        .catch(err => {
          dispatch({ type: 'SESSION_SAVE_FAILURE', error: err.response.body.error.message })
          throw err
        })
    }
  },

  remove: (session, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'SESSION_REMOVE' })
      return APIRequest.delete(dispatch, `sessions/${session.id}`, session, includes)
        .then(res => {
          dispatch({
            type: 'EVENT_SESSION_REMOVED',
            eventId: session.event_id,
            id: session.id
          })
          return dispatch({
            type: 'SESSION_REMOVE_SUCCESS',
            id: session.id
          })
        })
        .catch(err => {
          dispatch({ type: 'SESSION_REMOVE_FAILURE', error: err.response.body.error.message })
          throw err
        })
    }
  },

  resyncLessons: eventId => {
    return (dispatch, getState) => {
      return APIRequest.patch(dispatch, `events/${eventId}/groups/resync`).catch(err => {
        throw err
      })
    }
  },

  syncLessons: (eventId, payload) => {
    return (dispatch, getState) => {
      return APIRequest.patch(dispatch, `events/${eventId}/groups`, payload).catch(err => {
        throw err
      })
    }
  },

  lessonSelector: (sessionId, searchTerm, page, perPage, orderBy, showSelectedOnly) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          query (
            $searchTerm: String
            $page: Int!
            $perPage: Int!
            $showSelectedOnly: Boolean,
            $sessionId: ID!
            $orderBy: [SessionLessonSelectorOrderByOrderByClause!]
          ) {
            session(id: $sessionId) {
              lessonSelector(
                searchTerm: $searchTerm
                showSelectedOnly: $showSelectedOnly,
                page: $page
                first: $perPage
                orderBy: $orderBy
              ) {
                data {
                  id
                  lesson_id
                  group_id
                  teacher_id
                  class
                  teacher
                  student_count
                  subject
                  selected
                  reassigned
                }
          
                paginatorInfo {
                  total
                  currentPage
                  hasMorePages
                  perPage
                }
              }
            }
          }
        `,

        variables: {
          sessionId,
          searchTerm,
          page,
          perPage,
          orderBy,
          showSelectedOnly
        }
      }

      return graphQLRequest.send(query)
    }
  },

  addLesson: (defaultUserId, event, session, lesson, teacher, includes = ['sessions', 'lessons', 'event']) => {
    return (dispatch, getState) => {
      // we want to find groups with the same teacher
      const groups = getState().groups.groups
      const sessionGroups = session.groups.map(groupId => groups[groupId])
      const groupToAddLesson = sessionGroups.find(group => {
        if (!group) {
          return false
        }
        if (!teacher) {
          if (!group.owner_id || group.owner_id === defaultUserId) {
            return true
          }
          return false
        }
        if (group.owner_id !== teacher.user_id) {
          return false
        }
        return true
      })
      // dispatch a reducer for us firing the ajax request to the API for creating a group
      dispatch({
        type: 'LESSON_ADD_GROUPS',
        lessonId: lesson.id
      })

      dispatch({
        type: 'EVENT_UPDATED',
        eventId: event.id
      })

      // if we don't have a matching group, we should create a new one
      if (!groupToAddLesson) {
        const newGroupParams = {
          title: teacher ? `${teacher.surname}, ${teacher.salutation}` : 'Unassigned',
          user_id: teacher && teacher.user_id ? teacher.user_id : defaultUserId,
          lessons: [lesson.id],
          sessions: [session.id]
        }

        // fire the request
        return APIRequest.post(dispatch, `events/${event.id}/groups`, newGroupParams, includes)
          .then(res => {
            const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.group)
            // dispatch a reducer to handle the new group
            dispatch({
              type: 'GROUP_CREATE_SUCCESS',
              groups: output.entities.groups
            })
            dispatch({
              type: 'SESSIONS_ADD_GROUP_SUCCESS',
              groupId: output.result,
              sessionIds: [session.id]
            })
            dispatch({
              type: 'LESSON_ADD_GROUPS_SUCCESS',
              groupIds: [output.result],
              lessonId: lesson.id
            })
            dispatch({
              type: 'EVENT_ADD_GROUP_SUCCESS',
              eventId: event.id,
              groupId: output.result
            })
            return dispatch({
              type: 'EVENT_UPDATED',
              eventId: event.id,
              groupId: output.result
            })
          })
          .catch(err => {
            console.error('failed to create new group', err.message)
            // something went wrong, dispatch reducer to handle this
            dispatch({ type: 'GROUP_CREATE_FAILURE', error: err.message })
            throw err
          })
      } else {
        const modifiedGroupParams = Object.assign({}, groupToAddLesson, {
          lessons: [...groupToAddLesson.lessons, lesson.id]
        })

        // fire the request
        return APIRequest.patch(dispatch, `groups/${modifiedGroupParams.id}`, modifiedGroupParams, includes)
          .then(res => {
            const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.group)
            // dispatch a reducer to handle the new group
            dispatch({
              type: 'GROUP_UPDATE_SUCCESS',
              group: output.entities.groups[output.result],
              id: output.result
            })
            dispatch({
              type: 'LESSON_ADD_GROUPS_SUCCESS',
              groupIds: [output.result],
              lessonId: lesson.id
            })
            return dispatch({
              type: 'EVENT_UPDATED',
              eventId: event.id,
              groupId: modifiedGroupParams.id
            })
          })
          .catch(err => {
            console.error('failed to update group', err.message)
            // something went wrong, dispatch reducer to handle this
            dispatch({ type: 'GROUP_UPDATE_FAILURE', error: err.message })
            throw err
          })
      }
    }
  },
  removeLesson: (event, session, lesson, includes = ['lessons', 'sessions'], disableLessonSync = false) => {
    return (dispatch, getState) => {
      // we need find to the group with the lesson
      const groups = getState().groups.groups
      const sessionGroups = session.groups.map(groupId => groups[groupId])
      const groupToRemoveLessonFrom = sessionGroups.find(group => group && group.lessons.indexOf(lesson.id) > -1)
      if (!groupToRemoveLessonFrom) {
        dispatch({
          type: 'LESSON_REMOVE_GROUP_FAILURE',
          error: 'The group could not be removed'
        })
        return
      }
      dispatch({
        type: 'LESSON_REMOVE_GROUP',
        lessonId: lesson.id
      })

      dispatch({
        type: 'EVENT_UPDATED',
        eventId: event.id
      })

      if (groupToRemoveLessonFrom.lessons.length <= 1) {
        dispatch({
          type: 'GROUP_DELETE'
        })

        return APIRequest.delete(dispatch, `groups/${groupToRemoveLessonFrom.id}`, includes)
          .then(res => {
            dispatch({
              type: 'LESSON_REMOVE_GROUP_SUCCESS',
              lessonId: lesson.id,
              groupId: groupToRemoveLessonFrom.id
            })

            dispatch({
              type: 'SESSIONS_REMOVE_GROUP_SUCCESS',
              sessionIds: [session.id],
              groupId: groupToRemoveLessonFrom.id
            })

            dispatch({
              type: 'EVENT_REMOVE_GROUP_SUCCESS',
              eventId: event.id,
              groupId: groupToRemoveLessonFrom.id
            })

            dispatch({
              type: 'GROUP_DELETE_SUCCESS',
              groupId: groupToRemoveLessonFrom.id
            })
            return dispatch({
              type: 'EVENT_UPDATED',
              eventId: event.id
            })
          })
          .catch(err => {
            console.error('failed to delete group', err.message)
            // something went wrong, dispatch reducer to handle this
            dispatch({ type: 'GROUP_DELETE_FAILURE', error: err.message })
            dispatch({ type: 'LESSON_REMOVE_GROUP_FAILURE', error: err.message })
            throw err
          })
      } else {
        const updatedGroup = Object.assign({}, groupToRemoveLessonFrom, {
          disableLessonSync,
          lessons: [...groupToRemoveLessonFrom.lessons.filter(groupLesson => lesson.id !== groupLesson)]
        })

        dispatch({
          type: 'LESSON_REMOVE_GROUP'
        })

        return APIRequest.patch(dispatch, `groups/${groupToRemoveLessonFrom.id}`, updatedGroup, includes)
          .then(res => {
            const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.group)

            dispatch({
              type: 'LESSON_REMOVE_GROUP_SUCCESS',
              lessonId: lesson.id,
              groupId: groupToRemoveLessonFrom.id
            })

            dispatch({
              type: 'GROUP_UPDATE_SUCCESS',
              group: output.entities.groups[output.result],
              id: output.result
            })
            return dispatch({
              type: 'EVENT_UPDATED',
              eventId: event.id
            })
          })
          .catch(err => {
            console.error('failed to remove lesson from group', err.message)
            // something went wrong, dispatch reducer to handle this
            dispatch({ type: 'LESSON_REMOVE_GROUP_FAILURE', error: err.message })
            throw err
          })
      }
    }
  },

  updateGroupLessons: (sessionId, addedLessonTeacherMap, lessonIdsToRemove) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `  
        mutation($input: UpdateSessionGroupLessonsInput!) {
          updateSessionGroupLessons(input: $input)
        }
      `,
        variables: {
          input: {
            session_id: sessionId,
            added_lesson_teacher_map: addedLessonTeacherMap,
            removed_lesson_ids: lessonIdsToRemove
          }
        }
      }

      return graphQLRequest.send(query)
    }
  },

  validation: errors => {
    return dispatch => {
      dispatch({
        type: 'SESSION_VALIDATION',
        validation: errors
      })
    }
  },

  validationClear: () => {
    return dispatch => {
      dispatch({
        type: 'SESSION_VALIDATION_CLEAR'
      })
    }
  }
}

export default sessions
