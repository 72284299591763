import GraphQLRequest from '../services/graphql.service'

const downloads = {
  auditLog: eventId => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
        query ($eventId: ID!) {
          event(id: $eventId) {
            exportAuditLog
          }
        }`,

        variables: {
          eventId: eventId
        }
      }

      return graphQLRequest.send(query)
    }
  }
}

export default downloads
