export default function importTypes (state, action) {
  state = state || {
    error: null,
    fetchedAt: null,
    fetchingImportTypes: false,
    importTypes: {}
  }

  switch (action.type) {
    case 'IMPORT_TYPES_LIST':
      return Object.assign({}, state, {
        fetchingImportTypes: true
      })
    case 'IMPORT_TYPES_LIST_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        fetchingImportTypes: false
      })
    case 'IMPORT_TYPES_LIST_SUCCESS':
      return Object.assign({}, state, {
        fetchedAt: new Date(),
        fetchingImportTypes: false,
        importTypes: Object.assign({}, state.importTypes, action.importTypes)
      })

    default:
      return state
  }
}
