import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'
import GraphQLRequest from '../services/graphql.service'

const defaultIncludes = []

const groups = {
  duplicate: (clientId, eventId, sessionId, sourceGroupId, sourceLessonIds, userId) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
        mutation($input: DuplicateGroupInput!) {
          duplicateGroup(input: $input) {
            id
            title
          }
        }`,

        variables: {
          input: {
            client_id: clientId,
            event_id: eventId,
            session_id: sessionId,
            source_group_id: sourceGroupId,
            source_lesson_ids: sourceLessonIds,
            user_id: userId
          }
        }
      }

      return graphQLRequest.send(query)
    }
  },

  groupLessonStudents: (groupId, lessonId) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          query($groupId: ID!, $lessonId: ID!) {
            group(id: $groupId) {
              id
              lessons(lesson_id: $lessonId) {
                id
                groupStudents {
                  id
                }
              }
            }
          }
        `,

        variables: {
          groupId,
          lessonId
        }
      }

      return graphQLRequest.send(query)
    }
  },

  groupLessonStudentsDiff: (groupId, lessonId) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          query($groupId: ID!, $lessonId: ID!) {
            group(id: $groupId) {
              id
              lessons(lesson_id: $lessonId) {
                id
                name
                subject {
                  id
                  name
                }
                groupStudentsDiff {
                  id
                  forename
                  surname
                  diff_status
                  booking_slot_count
                }
              }
            }
          }
        `,

        variables: {
          groupId,
          lessonId
        }
      }

      return graphQLRequest.send(query)
    }
  },

  update: (groupId, newProps, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'GROUP_UPDATE', groupId: groupId })
      return APIRequest.patch(dispatch, `groups/${groupId}`, newProps, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.group)

          return dispatch({
            type: 'GROUP_UPDATE_SUCCESS',
            id: output.result,
            group: output.entities.groups[output.result]
          })
        })
        .catch(err => {
          console.error('failed to update group', err.message)
          dispatch({ type: 'GROUP_UPDATE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  updateGroupLessonStudents: (groupId, lessonStudents) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          mutation($input: UpdateGroupLessonStudentsInput!) {
            updateGroupLessonStudents(input: $input) {
              bookingSlotsUnbooked {
                id
                slot_id
              }
            }
          }
        `,

        variables: {
          input: {
            allow_booking_deletions: true,
            group_id: groupId,
            student_ids_by_lesson_ids: lessonStudents,
            send_cancellation_emails: false,
            cancellation_email_intro: ''
          }
        }
      }

      return graphQLRequest.send(query)
    }
  }
}

export default groups
