const defaultState = {
  error: null,
  fetchingLog: false,
  log: []
}

export default function log (state = defaultState, action) {
  switch (action.type) {
    case 'LOG':
      return Object.assign({}, state, {
        fetchingLog: true
      })
    case 'LOG_FAILURE':
      return Object.assign({}, state, {
        error: action.error,
        fetchingLog: false
      })
    case 'LOG_SUCCESS':
      return Object.assign({}, state, {
        fetchingLog: false,
        log: [...action.log]
      })
    default:
      return state
  }
}
