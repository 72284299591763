const flows = {
  2: [
    {
      endpoint: '',
      title: 'Event Information'
    },
    {
      endpoint: 'sessions',
      title: 'Sessions'
    },
    {
      endpoint: 'students',
      title: 'Students'
    },
    {
      endpoint: 'staff',
      title: 'Staff'
    },
    {
      endpoint: 'parents',
      title: 'Parents'
    }
  ]
}

const activate = (typeId, indexToActivate) => {
  const flow = flows[typeId]
  if (!flow) return null
  indexToActivate = validateIndex(flow, indexToActivate)
  return flow.map((step, index) => {
    if (index === indexToActivate) {
      return Object.assign({}, step, {
        isActive: true
      })
    }
    return step
  })
}

const validateIndex = (flow, indexToActivate) => {
  if (indexToActivate < 0) return 0
  const finalIndex = flow.length - 1
  if (indexToActivate > finalIndex) return finalIndex
  return indexToActivate
}

const getIndexByEndpoint = (typeId, existingEndpoint) => {
  const flow = flows[typeId]
  if (!flow) return 0
  return flow.findIndex(step => step.endpoint === existingEndpoint)
}

const FlowService = {
  current: (typeId, existingEndpoint) => {
    const existingIndex = getIndexByEndpoint(typeId, existingEndpoint)
    return activate(typeId, existingIndex)
  },
  next: (typeId, existingEndpoint) => {
    const existingIndex = getIndexByEndpoint(typeId, existingEndpoint)
    return activate(typeId, existingIndex + 1)
  },
  previous: (typeId, existingEndpoint) => {
    const existingIndex = getIndexByEndpoint(typeId, existingEndpoint)
    return activate(typeId, existingIndex - 1)
  }
}

export default FlowService
