const dotProp = require('dot-prop-immutable')

export default function bookings (state, action) {
  state = state || {
    error: null,
    bookings: {}
  }

  switch (action.type) {
    case 'BOOKINGS_CLEAR':
      return Object.assign({}, state, {
        bookings: {}
      })
    case 'BOOKING_CREATE_SUCCESS':
      return Object.assign({}, state, {
        bookings: Object.assign({}, state.bookings, {
          [action.booking.id]: action.booking
        })
      })
    case 'BOOKINGS_FETCHED':
      return Object.assign({}, state, {
        bookings: action.bookings
      })

    case 'BOOK_SLOT_SUCCESS': {
      const { bookingId, bookingSlots } = action
      return dotProp.set(state, `bookings.${bookingId}.bookingSlots`, bookingSlots)
    }

    case 'BOOKING_SLOT_UPDATE_SUCCESS': {
      const { bookingId, bookingSlots } = action
      return dotProp.set(state, `bookings.${bookingId}.bookingSlots`, bookingSlots)
    }

    case 'BOOKING_SLOT_COMMENT_CREATE_SUCCESS': {
      const { bookingId, bookingSlotId, comment } = action
      const bookingSlotIndex = state.bookings[bookingId].bookingSlots.findIndex(
        bookingSlot => bookingSlot.id === bookingSlotId
      )

      return dotProp.merge(state, `bookings.${bookingId}.bookingSlots.${bookingSlotIndex}.comments`, comment.id)
    }

    case 'BOOKING_SLOT_COMMENT_DELETE_SUCCESS': {
      const { bookingId, bookingSlotId, commentId } = action
      const bookingSlotIndex = state.bookings[bookingId].bookingSlots.findIndex(
        bookingSlot => bookingSlot.id === bookingSlotId
      )

      const commentIndex = state.bookings[bookingId].bookingSlots[bookingSlotIndex].comments.findIndex(
        id => id === commentId
      )

      return dotProp.delete(state, `bookings.${bookingId}.bookingSlots.${bookingSlotIndex}.comments.${commentIndex}`)
    }

    case 'UNBOOK_SLOT_SUCCESS':
      const bookingSlots = action.bookingSlots
      if (bookingSlots.length) {
        return Object.assign({}, state, {
          bookings: Object.assign({}, state.bookings, {
            [action.bookingId]: Object.assign({}, state.bookings[action.bookingId], {
              bookingSlots: action.bookingSlots
            })
          })
        })
      }
      let newBookings = Object.assign({}, state.bookings)
      delete newBookings[action.bookingId]
      return Object.assign({}, state, {
        bookings: newBookings
      })

    default:
      return state
  }
}
