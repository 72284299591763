import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'
import GraphQLRequest from '../services/graphql.service'

const defaultIncludes = ['sessions.groups', 'groups.lessons']

const events = {
  auditLog: (eventId, page, perPage, order, searchTerm, types) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          query (
            $eventId: ID!
            $first: Int!
            $page: Int!
            $order: [EventAuditLogOrderByOrderByClause!]
            $searchTerm: String
            $types: [ID!]
          ) {
            event(id: $eventId) {
              auditLog(
                first: $first
                page: $page
                orderBy: $order
                searchTerm: $searchTerm
                types: $types
              ) {
                paginatorInfo {
                  total
                  currentPage
                  hasMorePages
                  perPage
                }
          
                data {
                  id
                  details
                  summary
                  
                  type {
                    code
                    description
                  }
          
                  user {
                    name
                  }
          
                  created_at
                }
              }
            }
          }          
        `,

        variables: {
          eventId: eventId,
          page,
          first: perPage,
          order,
          searchTerm,
          types
        }
      }

      return graphQLRequest.send(query)
    }
  },

  list: (includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'EVENT_LIST' })
      return APIRequest.get(dispatch, 'events', includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [SchemaService.schemas.event])

          if (output.entities.sessions) {
            dispatch({
              type: 'SESSIONS_FETCHED',
              sessions: output.entities.sessions
            })
          }
          if (output.entities.clients) {
            dispatch({
              type: 'CLIENT_FETCH_SUCCESS',
              clients: output.entities.clients
            })
          }
          return dispatch({
            type: 'EVENT_LIST_SUCCESS',
            events: output.entities.events,
            eventList: output.result
          })
        })
        .catch(err => {
          console.error('failed to get event list', err.message)
          dispatch({ type: 'EVENT_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  },

  get: (id, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'EVENT_GET' })
      return APIRequest.get(dispatch, 'events/' + id, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.event)

          if (output.entities.clients) {
            dispatch({
              type: 'CLIENT_FETCH_SUCCESS',
              clients: output.entities.clients
            })
          }
          if (output.entities.invitations) {
            dispatch({
              type: 'INVITATIONS_FETCHED',
              invitations: output.entities.invitations
            })
          }
          if (output.entities.invitationGroups) {
            dispatch({
              type: 'INVITATION_GROUPS_FETCHED',
              invitationGroups: output.entities.invitationGroups
            })
          }
          if (output.entities.staff) {
            dispatch({
              type: 'STAFF_FETCHED',
              staff: output.entities.staff
            })
          }
          if (output.entities.students) {
            dispatch({
              type: 'STUDENTS_FETCHED',
              students: output.entities.students
            })
          }
          if (output.entities.guardians) {
            dispatch({
              type: 'GUARDIANS_FETCHED',
              guardians: output.entities.guardians
            })
          }
          if (output.entities.subjects) {
            dispatch({
              type: 'SUBJECTS_FETCHED',
              subjects: output.entities.subjects
            })
          }
          if (output.entities.lessons) {
            dispatch({
              type: 'LESSONS_FETCHED',
              lessonList: output.entities.lessons
            })
          }
          if (output.entities.location) {
            dispatch({
              type: 'LOCATIONS_FETCHED',
              locationList: output.entities.location
            })
          }
          if (output.entities.sessions) {
            dispatch({
              type: 'SESSIONS_FETCHED',
              sessions: output.entities.sessions
            })
          }
          if (output.entities.groups) {
            dispatch({
              type: 'GROUPS_FETCHED',
              groups: output.entities.groups
            })
          }

          if (output.entities.groupLessonNotes) {
            dispatch({
              type: 'GROUP_LESSON_NOTES_FETCHED',
              groupLessonNotes: output.entities.groupLessonNotes
            })
          }

          if (output.entities.slots) {
            dispatch({
              type: 'SLOTS_FETCHED',
              slots: output.entities.slots
            })
          }

          if (output.entities.comments) {
            dispatch({
              type: 'COMMENTS_FETCHED',
              comments: output.entities.comments
            })
          }

          if (output.entities.bookings) {
            dispatch({
              type: 'BOOKINGS_FETCHED',
              bookings: output.entities.bookings
            })
          }

          if (output.entities.user) {
            dispatch({
              type: 'USERS_FETCHED',
              users: output.entities.user
            })
          }
          return dispatch({
            type: 'EVENT_GET_SUCCESS',
            events: output.entities.events,
            eventId: output.result
          })
        })
        .catch(err => {
          console.error('failed to get event', err)
          dispatch({ type: 'EVENT_GET_FAILURE', error: err.message })
          throw err
        })
    }
  },
  delete: eventId => {
    return dispatch => {
      dispatch({ type: 'EVENT_DELETE' })
      return APIRequest.delete(dispatch, `events/${eventId}`)
        .then(res =>
          dispatch({
            type: 'EVENT_DELETE_SUCCESS',
            id: eventId
          })
        )
        .catch(err => {
          dispatch({ type: 'EVENT_DELETE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  update: (id, property, value) => {
    return dispatch => {
      dispatch({ type: 'EVENT_UPDATE', id: id, property: property, value: value })
    }
  },

  save: (eventModel, includes = defaultIncludes) => {
    return dispatch => {
      if (eventModel.id === 0) {
        // if it's a new event
        dispatch({ type: 'EVENT_CREATE' })
        return APIRequest.post(dispatch, `clients/${eventModel.client_id}/events`, eventModel, includes)
          .then(res => {
            const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.event)

            return dispatch({
              type: 'EVENT_CREATE_SUCCESS',
              eventModel: output.entities.events[output.result]
            })
          })
          .catch(err => {
            dispatch({ type: 'EVENT_CREATE_FAILURE', error: err.message })
            throw err
          })
      } else {
        // otherwise it's an existing event
        dispatch({ type: 'EVENT_SAVE' })
        return APIRequest.patch(dispatch, `events/${eventModel.id}`, eventModel, includes)
          .then(res => {
            const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.event)
            return dispatch({
              type: 'EVENT_SAVE_SUCCESS',
              event: output.entities.events[eventModel.id]
            })
          })
          .catch(err => {
            dispatch({ type: 'EVENT_SAVE_FAILURE', error: err.message })
            throw err
          })
      }
    }
  },
  add: () => {
    return dispatch => {
      dispatch({ type: 'EVENT_ADD' })
    }
  },
  validation: errors => {
    return dispatch => {
      dispatch({
        type: 'EVENT_VALIDATION',
        validation: errors
      })
    }
  },
  validationClear: () => {
    return dispatch => {
      dispatch({
        type: 'EVENT_VALIDATION_CLEAR'
      })
    }
  }
}

export default events
