export default function eventNotificationTypes (state, action) {
  state = state || {
    error: null,
    eventNotificationTypes: {},
    eventNotificationTypesList: []
  }

  switch (action.type) {
    case 'EVENT_NOTIFICATION_TYPE_LIST':
    case 'EVENT_NOTIFICATION_TYPE_LIST_FAILURE':
      return Object.assign({}, state, {
        error: action.error
      })
    case 'EVENT_NOTIFICATION_TYPE_LIST_SUCCESS':
      return Object.assign({}, state, {
        eventNotificationTypes: Object.assign({}, state.eventNotificationTypes, action.eventNotificationTypes),
        eventNotificationTypesList: action.eventNotificationTypesList
      })

    default:
      return state
  }
}
