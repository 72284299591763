import React from 'react'
import HelpItem from '../../../Organisms/HelpItem'

const ParentHelp = () => (
  <div className='parent-help'>
    <h5 className='mb-4'>Need a hand? Please select a category below.</h5>

    <HelpItem title='Devices' isOpen={false}>
      <div id='devices' className='devices'>
        <p>
          For the best experience we recommend using a computer (such as a laptop) equipped with a webcam, speakers and
          mic, or tablet device (iOS or Android). Whichever device you are using please install one of the compatible
          browsers.
        </p>

        <p className='mb-2'>
          <strong>Compatible Browsers</strong>
        </p>

        <dl>
          <dt>Windows PC</dt>
          <dd>Chrome, Firefox or Edge</dd>
          <dt>Android</dt>
          <dd>Chrome or Firefox</dd>
          <dt>Mac</dt>
          <dd>Safari, Chrome or Firefox</dd>
          <dt>iPhone / iPad</dt>
          <dd>Safari</dd>
        </dl>

        <img className='mb-2 img-fluid' src='/help/parents/devices.jpg' alt='Devices' width='500' />
      </div>
    </HelpItem>

    <HelpItem title='Activation &amp; Login' isOpen={false}>
      <div className='activation-login'>
        <strong>Initial login</strong>

        <p>
          You will receive an email from your School that provides a link to ‘Activate Account’. If you are expecting an
          email and it has not been received, please contact your school directly. Also check your spam/junk mail
          folders.
        </p>

        <p>
          Using the link contained in the email will open your browser to{' '}
          <a href='https://events.schoolbooking.com/' target='_blank' rel='noopener noreferrer'>
            https://events.schoolbooking.com/
          </a>{' '}
          and ask you to create a password for your account.
        </p>

        <strong>Future logins</strong>

        <p>
          The next time you login you will be asked for your email address (this will be the email address the school
          sent the invitation too) and the password you created.
        </p>

        <img className='mb-2 img-fluid' src='/help/parents/activation_login.png' alt='Activation / Login' width='500' />

        <p>
          We recommend you either bookmark or add <strong>events.schoolbooking.com</strong> as a favourite in your
          chosen browser. Here are browser links to guide on how to manage bookmarks:
        </p>

        <ul>
          <li>
            <a
              href='https://support.apple.com/en-gb/guide/iphone/iph42ab2f3a7/ios'
              target='_blank'
              rel='noopener noreferrer'
            >
              iOS/Safari
            </a>
          </li>

          <li>
            <a href='https://support.google.com/chrome/answer/188842' target='_blank' rel='noopener noreferrer'>
              Chrome
            </a>
          </li>

          <li>
            <a href='https://support.mozilla.org/en-US/kb/bookmarks-firefox' target='_blank' rel='noopener noreferrer'>
              Firefox
            </a>
          </li>

          <li>
            <a
              href='https://support.microsoft.com/en-us/microsoft-edge/add-a-site-to-my-favorites-in-microsoft-edge-eb40d818-fd1f-cb19-d943-6fcfd1d9a935'
              target='_blank'
              rel='noopener noreferrer'
            >
              MS Edge
            </a>
          </li>
        </ul>
      </div>
    </HelpItem>

    <HelpItem title='Reset Password' isOpen={false}>
      <div className='reset-password'>
        <p>
          If you cannot remember your login credentials, from the login page select <strong>Forgotten Password?</strong>{' '}
          This will take you to the account recovery page. There are 2 steps to recover your account.
        </p>

        <img className='mb-2 img-fluid' src='/help/parents/reset-password.jpg' alt='Reset Password' width='500' />
        <p>
          <strong>Step 1</strong> - enter your Email Address *, and then select "
          <strong>Send Password Reset Email</strong>".
        </p>

        <p>
          <strong>Step 2</strong> - Check your email for a message from SchoolBooking with the subject ‘Account recovery
          (the email sender is SchoolBooking <a href='mailto:noreply@schoolbooking.com'>noreply@schoolbooking.com</a>).
          The email will contain a Reset Password link. Click on the <strong>Reset Password</strong> link, this will
          open your browser and ask you to create a new password. You will then be logged in. * this must the email
          address that your school has recorded for you. They will have sent the event invitation email to this same
          email address. If the email address is not recognised, you will have to try again or contact your school to
          confirm details.
        </p>
      </div>
    </HelpItem>

    <HelpItem title='Event Dashboard' isOpen={false}>
      <div className='events-dashboard'>
        <p>
          Select <strong>"Events"</strong> from the top navigation, and you will be able to view the Events.
        </p>

        <img className='mb-2 img-fluid' src='/help/parents/event-dashboard.jpg' alt='Event Dashboard' width='500' />

        <p>
          Events are listed as "Upcoming" or "Past", and will include the event title, description, and dates. Select
          any of the events to start making appointments.
        </p>
      </div>
    </HelpItem>

    <HelpItem title='Attendance options' isOpen={false}>
      <div className='attendance-options'>
        <p>
          Parent Meeting events can be setup by your school organiser with either venue, telephone or video call
          attendance options. When you view your selected event, you will see these options and asked to make a choice
          (all options may not be available depending on how your school has setup the event).{' '}
        </p>

        <img
          className='mb-2 img-fluid'
          src='/help/parents/attendance-options-1.jpg'
          alt='Attendance Options'
          width='500'
        />

        <p>
          If you choose "Telephone Call", you will see the phone number that the school has recorded for you. If this
          has changed or you would like to be contacted on a different number, select <strong>"Update Phone"</strong>.
        </p>

        <img
          className='mb-2 img-fluid'
          src='/help/parents/attendance-options-2.jpg'
          alt='Attendance Options'
          width='500'
        />
      </div>
    </HelpItem>

    <HelpItem title='Booking Appointments' isOpen={false}>
      <div className='booking-appointments'>
        <p>
          Select <strong>"Events"</strong> from top navigation and choose the event you would like to book. Make sure
          you have selected how you will attend the event (if applicable) and scroll down to see the list of Teachers
          you can book with. Simply <strong>Select</strong> any Teacher and you will be offered all the available
          timeslots. You can also see your other teacher appointments to help group your meetings together.
        </p>

        <img
          className='mb-2 img-fluid'
          src='/help/parents/booking-appointments.jpg'
          alt='Booking appointments'
          width='500'
        />
      </div>
    </HelpItem>

    <HelpItem title='Print / Email Intinerary' isOpen={false}>
      <div id='print-email-itinerary' className='print-email-itinerary'>
        <p>
          Once you have booked all your appointments you can print and/or email your itinerary. Look for the buttons
          next the event title and description. Your school may also send you a reminder email of your appointments
          prior to the event start date.
        </p>

        <img
          className='mb-2 img-fluid'
          src='/help/parents/print-email-itinerary.jpg'
          alt='Print Email Itinerary'
          width='500'
        />
      </div>
    </HelpItem>

    <HelpItem title='How to attend telephone call appointments' isOpen={false}>
      <div className='attend-phone-appointments'>
        <p>
          Your telephone appointments will be started by the teacher. On the day of the event login to{' '}
          <a href='https://events.schoolbooking.com/' target='_blank' rel='noopener noreferrer'>
            https://events.schoolbooking.com/
          </a>{' '}
          and view your list of appointments (they will be in time order). Alternatively, print your itinerary so you
          are ready and know when expect the teacher calls.
        </p>
      </div>
    </HelpItem>

    <HelpItem title='How to attend video call appointments' isOpen={false}>
      <div className='attend-video-appointments'>
        <p>
          If you have selected "Video Call" as your attendance option, you can make bookings in the normal way. However,
          you will notice that when you book a timeslot with a teacher you will be provided with a "Join Meeting" link.
          These will be the links you need to use on the day of the Parent Meeting event.
        </p>

        <ol className='pl-3'>
          <li className='mb-2'>
            Before you start, make sure you are using a compatible browser and have a device with front facing camera
            and microphone (See Devices).
          </li>

          <li className='mb-2'>
            On the day of the event login to{' '}
            <a href='https://events.schoolbooking.com/' target='_blank' rel='noopener noreferrer'>
              https://events.schoolbooking.com/
            </a>{' '}
            or click the link on the email you have received from your school.
          </li>

          <li className='mb-2'>
            <p className='my-0'>
              Test your device before meetings start. We recommend that you test your device camera and audio before the
              event starts. To do this just select any Join Meeting link. This will open a new tab in your browser and
              you maybe asked for permission to use you microphone & camera. It is important to select "Allow". If you
              do not allow the video calls will not work correctly. To make changes these settings, see the{' '}
              <strong>troubleshooting</strong> help section. You should see your name, and the another Join Meeting
              option. If you want to test your camera you can. Select <strong>Join Meeting</strong> and you should see
              yourself and a "Waiting for the host…" message.
            </p>
            <img className='my-2 img-fluid' src='/help/parents/attend-video-call-1.jpg' alt='Video Call' width='500' />
          </li>

          <li className='mb-2'>
            Now you are ready to connect to a Video Call, check the time of your first appointment (they will be listed
            in time order) and select <strong>Join Meeting</strong> link when you are ready (this can be before the
            appointment start time. The teacher will connect at your appointment time.
            <img className='my-2 img-fluid' src='/help/parents/attend-video-call-2.jpg' alt='Video Call' width='500' />
            <p>
              When you select the Join Meeting link it will open a new video call tab in your browser. Select "Join
              Meeting" and you should see yourself and a "Waiting for host…" message.
            </p>
            <img className='mb-2 img-fluid' src='/help/parents/attend-video-call-3.jpg' alt='Video Call' width='500' />
          </li>

          <li className='mb-2'>
            <p>
              At your appointment start time the teacher will connect with you and start the meeting (note: it is
              possible the teacher maybe over running on the last meeting, so you may have a delayed start). In your
              video call you will have some basic controls
            </p>

            <img className='mb-2 img-fluid' src='/help/parents/attend-video-call-4.png' alt='Video Call' width='185' />

            <ul>
              <li>Click on the microphone button to enable or disable your microphone.</li>
              <li>Click on the phone button to end the call.</li>
              <li>Click on the camera button to turn your video on or off.</li>
            </ul>
          </li>

          <li className='mb-2'>
            <p className='my-0'>
              When your video call is finished, select the phone button to leave the call. You will then see a feedback
              request page. You can choose to submit feedback or close the browser tab to return to your appointments
              list. Check the time of your next appointment and join when ready as before.
            </p>
            <img className='my-2 img-fluid' src='/help/parents/attend-video-call-5.jpg' alt='Video Call' width='500' />
          </li>
        </ol>
      </div>
    </HelpItem>

    <HelpItem title='Troubleshooting Help - Camera and Microphone issues' isOpen={false}>
      <div className='troubleshooting'>
        <p>
          Most camera and microphone issues are related to access. The camera and microphone can only be connected to
          one application, so if you are using something like MS Teams or Skype it will help if you close these
          applications before starting to make video calls.
        </p>

        <p>
          The web browser will ask for access when it first connects to a Video call. If this setting is incorrect the
          microphone and camera maybe blocked.
        </p>

        <p className='mb-2'>Please see these guides that should help you unblock your device</p>

        <dl>
          <dt>Chrome</dt>
          <dd>
            <a href='https://support.google.com/chrome/answer/2693767' target='_blank' rel='noopener noreferrer'>
              Use your camera & microphone
            </a>
          </dd>

          <dt>Firefox</dt>

          <dd>
            <a
              href='https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#w_using-firefoxaos-address-bar-to-clear-camera-or-microphone-permissions-for-a-site'
              target='_blank'
              rel='noopener noreferrer'
            >
              How to manage your camera and microphone permissions with Firefox
            </a>
          </dd>

          <dt>Safari on Mac</dt>
          <dd>
            <a
              href='https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac'
              target='_blank'
              rel='noopener noreferrer'
            >
              Safari Change Websites preferences in Safari on Mac
            </a>
          </dd>

          <dt>iPad / iPhone users</dt>
          <dd>
            Go to Settings and search for the Safari application. Check Camera & Microphone Access is enabled
            <p className='mt-3'>
              <img src='/help/parents/troubleshooting.png' alt='Troubleshooting' width='210' />
            </p>
          </dd>
        </dl>
      </div>
    </HelpItem>
  </div>
)

export default ParentHelp
