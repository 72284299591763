import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'
import GraphQLRequest from '../services/graphql.service'
import * as Sentry from '@sentry/browser'

const user = {
  auth: (identifier, password) => {
    return dispatch => {
      dispatch({ type: 'USER_LOGIN' })
      return APIRequest.post(dispatch, 'token', {
        identifier,
        password
      })
    }
  },

  getFromToken: (token, includes = ['clientUser.role', 'clientUser.client']) => {
    return dispatch => {
      dispatch({ type: 'USER_GET' })
      return APIRequest.get(dispatch, 'token', includes)
        .then(res => {
          const userNormalised = normalize(
            SchemaService.helpers.removeNestedData(res.body.data),
            SchemaService.schemas.user
          )
          const userObject =
            userNormalised && userNormalised.result ? userNormalised.entities.user[userNormalised.result] : null
          if (userObject) {
            Sentry.configureScope(scope => {
              scope.setUser(userObject)
            })
          }
          return userNormalised
        })
        .catch(err => {
          dispatch({ type: 'USER_GET_FAILURE', error: err.message })
          throw err
        })
    }
  },

  get: (id, includes = []) => {
    return dispatch => {
      dispatch({ type: 'USER_GET' })
      return APIRequest.get(dispatch, `users/${id}`, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.user)
          const foundUser = output.entities.user[output.result]
          if (output.entities.clients) {
            dispatch({
              type: 'CLIENT_FETCH_SUCCESS',
              clients: output.entities.clients
            })
          }
          dispatch({
            type: 'USER_GET_SUCCESS',
            user: foundUser,
            clients: foundUser.clients
          })
        })
        .catch(err => {
          dispatch({ type: 'USER_GET_FAILURE', error: err.message })
          throw err
        })
    }
  },

  // GraphQL Parent Portal Token
  parentPortalImpersonate: (clientId, userId) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const mutation = {
        query: 'mutation($input: ImpersonateInput!) { impersonate(input: $input) }',
        variables: { input: { client_id: clientId, user_id: userId, type: 'PARENTS' } }
      }

      return graphQLRequest.send(mutation)
    }
  },

  resetAccount: (clientId, userId, password, passwordConfirmation) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `mutation($input: ResetAccountInput!) {
          resetAccount(input: $input)
        }`,

        variables: {
          input: {
            client_id: clientId,
            user_id: userId,
            password: password,
            password_confirmation: passwordConfirmation
          }
        }
      }

      return graphQLRequest.send(query)
    }
  },

  list: (clientId, currentPage, perPage, order, searchTerm, where, eventsWhere, showDetails) => {
    const graphQLRequest = new GraphQLRequest()

    let mainFragment = `
      id
      email
      last_active
      activated_at
      mobile
      
      guardians {
        id
        salutation
        forename
        surname
        active
      }
    `
    if (!showDetails) {
      mainFragment =
        mainFragment +
        `events(where: $eventsWhere) {
        id
      }`
    }

    const showDetailsFragment = `
      events(where: $eventsWhere, orderBy: [ { sessions: { aggregate: MAX, column: STARTS_AT}, order: DESC }]) {
        id
        title
        start_date
        end_date
      }

      bookings {
        id
        event_id
        bookingSlots {
          id
          attended_quantity
        }
      }
    `

    const fields = showDetails ? mainFragment + showDetailsFragment : mainFragment

    return dispatch => {
      const query = {
        query: `query (
          $clientId: ID!, 
          $page: Int!, 
          $perPage: Int!, 
          $searchTerm: String, 
          $order: [ClientUsersOrderByRelationOrderByClause!],
          $eventsWhere: UserEventsWhereWhereConditions,
          $where: ClientUsersWhereWhereConditions
        ) {
          client(id: $clientId) {
            users(
              first: $perPage, 
              page: $page, 
              search_term: $searchTerm, 
              orderBy: $order, 
              where: $where
            ) {
              data {
                ${fields}
              }
          
              paginatorInfo {
                currentPage
                hasMorePages
                total
              }
            }
          }
        }`,

        variables: {
          page: currentPage,
          perPage: perPage,
          order: order,
          clientId: clientId,
          searchTerm: searchTerm,
          where: where,
          eventsWhere: eventsWhere
        }
      }

      return graphQLRequest.send(query)
    }
  },

  loginAs: (clientId, userId) => {
    return dispatch => {
      dispatch({ type: 'USER_IMPERSONATE' })
      return APIRequest.post(dispatch, `/clients/${clientId}/users/${userId}/impersonate`)
        .then(res => {
          const token = res.body.data.token
          dispatch({
            type: 'USER_IMPERSONATE_SUCCESS',
            token: token
          })
        })
        .catch(err => {
          dispatch({ type: 'USER_IMPERSONATE_FAILURE', error: err.message })
          throw err
        })
    }
  },

  revertLoginAs: () => {
    return dispatch => {
      dispatch({ type: 'USER_IMPERSONATE_STOP' })
    }
  },

  impersonate: userId => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        return resolve(
          dispatch({
            type: 'USER_IMPERSONATE',
            userId
          })
        )
      })
    }
  },

  set: userToSet => {
    return dispatch => {
      return new Promise((resolve, reject) => {
        if (!userToSet || !userToSet.entities) {
          return reject(new Error('Malformed user'))
        }
        if (userToSet.entities.clients) {
          dispatch({
            type: 'CLIENT_FETCH_SUCCESS',
            clients: userToSet.entities.clients
          })
        }
        if (userToSet.entities.rights) {
          dispatch({
            type: 'RIGHTS_FETCHED',
            rights: userToSet.entities.rights
          })
        }
        dispatch({
          type: 'USER_GET_SUCCESS',
          user: userToSet.entities.user[userToSet.result],
          clients: userToSet.entities.user[userToSet.result].clients
        })
        return resolve(
          dispatch({
            type: 'USER_LOGIN_SUCCESS',
            newUser: userToSet.entities.user[userToSet.result],
            roles: userToSet.entities.role || {},
            clientUsers: userToSet.entities.clientUser || {}
          })
        )
      })
    }
  },

  setToken: token => {
    return dispatch =>
      new Promise((resolve, reject) => {
        localStorage.setItem('apiToken', token)
        return resolve(
          dispatch({
            type: 'USER_TOKEN_SET',
            token
          })
        )
      })
  },

  logout: () => {
    return dispatch =>
      new Promise((resolve, reject) => {
        localStorage.removeItem('apiToken')
        return resolve(dispatch({ type: 'USER_LOGOUT' }))
      })
  },

  register: emailAddress => {
    return dispatch => {
      dispatch({ type: 'USER_REGISTER' })
      return APIRequest.post(dispatch, 'users', {
        email: emailAddress
      })
    }
  },

  requestCode: identifier => {
    return dispatch => {
      dispatch({ type: 'CODE_REQUEST' })
      return APIRequest.patch(dispatch, 'users', {
        identifier
      })
    }
  },

  validateCode: (identifier, code) => {
    return dispatch => {
      dispatch({ type: 'CODE_VALIDATE' })
      return APIRequest.post(dispatch, 'token', {
        identifier,
        code
      })
    }
  },

  emailUpdate: (userId, email) => {
    return dispatch => {
      return APIRequest.patch(dispatch, `users/${userId}/email`, { email }).then(res => {
        dispatch({ type: 'USER_EMAIL_UPDATED', user: res.body.data })
      })
    }
  },

  passwordUpdate: payload => {
    return (dispatch, getState) => {
      dispatch({ type: 'PASSWORD_UPDATE' })
      return APIRequest.patch(dispatch, `users/${getState().user.user.id}`, payload)
    }
  },

  update: payload => {
    return dispatch => {
      return dispatch({
        type: 'USER_UPDATE',
        userObject: payload
      })
    }
  },

  save: () => {
    return (dispatch, getState) => {
      dispatch({ type: 'USER_SAVE' })
      const userPayload = getState().user.user
      return APIRequest.patch(dispatch, `users/${userPayload.id}`, userPayload)
    }
  }
}

export default user
