export default function steps (state, action) {
  state = state || []

  switch (action.type) {
    case 'STEP_CHANGE':
      return action.flow

    default:
      return state
  }
}
