import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import GraphQLRequest from '../services/graphql.service'
import SchemaService from '../services/schema.service'

const defaultIncludes = ['teacher', 'subject', 'groups']

const lessons = {
  filter: filterString => {
    return dispatch => {
      dispatch({ type: 'LESSONS_FILTER', lessonsFilterString: filterString })
    }
  },

  reset: () => {
    return dispatch => {
      dispatch({ type: 'LESSONS_RESET' })
    }
  },

  students: lessonId => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          query($id: ID!) {
            lesson(id: $id) {
              id
              name
              subject {
                id
                name
              }

              students {
                id
                forename
                surname
                active
              }
            }
        }`,

        variables: {
          id: lessonId
        }
      }

      return graphQLRequest.send(query)
    }
  },

  get: (clientId, searchTerm, page, perPage, orderBy, filterByIds) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `
          query (
            $searchTerm: String, 
            $page: Int!, 
            $perPage: Int!, 
            $clientId: ID!
            $orderBy: [ClientLessonsOrderByOrderByClause!]
            $filterByIds: [ID]
          ) {
              client(id: $clientId) {
                lessons (
                  searchTerm: $searchTerm, 
                  page: $page, 
                  first: $perPage
                  orderBy: $orderBy
                  filterByIds: $filterByIds
                ) {
                    data {
                      id
                      name
                      main_teacher
                      studentCount

                      subject {
                        id
                        name
                      }
                      
                      teacher {
                        id
                        salutation
                        forename
                        surname
                        user_id
                      }
                    }

                    paginatorInfo {
                      total
                      currentPage
                      hasMorePages
                      perPage
                    }
                  }
                }
              }`,

        variables: {
          clientId,
          searchTerm,
          page,
          perPage,
          orderBy,
          filterByIds
        }
      }

      return graphQLRequest.send(query)
    }
  },

  list: (clientId, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'LESSON_LIST' })

      return APIRequest.get(dispatch, `clients/${clientId}/lessons`, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), [SchemaService.schemas.lesson])
          if (output.entities.guardians) {
            dispatch({
              type: 'GUARDIANS_FETCHED',
              guardians: output.entities.guardians
            })
          }
          if (output.entities.students) {
            dispatch({
              type: 'STUDENTS_FETCHED',
              students: output.entities.students
            })
          }
          if (output.entities.staff) {
            dispatch({
              type: 'STAFF_FETCHED',
              staff: output.entities.staff
            })
          }
          if (output.entities.subjects) {
            dispatch({
              type: 'SUBJECTS_FETCHED',
              subjects: output.entities.subjects
            })
          }

          dispatch({
            type: 'CLIENT_LESSONS_FETCHED',
            clientId: clientId,
            lessons: output.result
          })
          return dispatch({
            type: 'LESSON_LIST_SUCCESS',
            lessonList: output.entities.lessons || []
          })
        })
        .catch(err => {
          console.error('failed to get lesson list', err.message)
          dispatch({ type: 'LESSON_LIST_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default lessons
