import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfills/contains'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

// import registerServiceWorker from './registerServiceWorker'

// Our app styles.
import './assets/style/app.css'
require('intersection-observer') // For IE 11 support

ReactDOM.render(<App />, document.getElementById('root'))
// registerServiceWorker()
