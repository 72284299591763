import APIRequest from '../services/api.service'
import { normalize } from 'normalizr'
import SchemaService from '../services/schema.service'
import GraphQLRequest from '../services/graphql.service'

const defaultIncludes = []

const students = {
  list: (clientId, page, perPage, orderBy, searchTerm) => {
    const graphQLRequest = new GraphQLRequest()

    return dispatch => {
      const query = {
        query: `query (
          $searchTerm: String, 
          $page: Int!, 
          $perPage: Int!, 
          $clientId: ID!, 
          $orderBy: [ClientStudentsOrderByOrderByClause!]
        ) {
            client(id: $clientId) {
              students (
                searchTerm: $searchTerm, 
                page: $page, 
                first: $perPage, 
                orderBy: $orderBy
              ) {
                  data {
                    id
                    forename
                    surname
                  }
                    
                  paginatorInfo {
                    total
                    currentPage
                    hasMorePages
                    perPage
                  }
                }
              }
            }
          `,

        variables: {
          clientId,
          searchTerm,
          page,
          perPage,
          orderBy
        }
      }

      return graphQLRequest.send(query)
    }
  },

  show: (studentId, includes = defaultIncludes) => {
    return dispatch => {
      dispatch({ type: 'STUDENT_GET' })
      return APIRequest.get(dispatch, `students/${studentId}`, includes)
        .then(res => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.student)
          if (output.entities.guardians) {
            dispatch({
              type: 'GUARDIANS_FETCHED',
              guardians: output.entities.guardians
            })
          }
          if (output.entities.guardianStudents) {
            dispatch({
              type: 'GUARDIAN_STUDENTS_FETCHED',
              guardianStudents: output.entities.guardianStudents
            })
          }

          if (output.entities.user) {
            dispatch({
              type: 'USERS_FETCHED',
              users: output.entities.user
            })
          }

          return dispatch({
            type: 'STUDENT_GET_SUCCESS',
            students: output.entities.students,
            studentList: output.result
          })
        })
        .catch(err => {
          console.error('could not get student list', err.message)
          dispatch({ type: 'STUDENT_GET_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default students
