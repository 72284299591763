import * as Sentry from '@sentry/react'

const LoggerService = {
  init: () => {
    Sentry.init({
      dsn: 'https://3ca8b1db59134908bc8f4822d112631a@o122780.ingest.sentry.io/270283',
      environment: process.env.REACT_APP_ENV
    })
  },
  error: message => {
    return new Promise(resolve => {
      resolve(Sentry.captureException(message))
    })
  }
}

export default LoggerService
