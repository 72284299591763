export default function lessons (state, action) {
  state = state || {
    lessons: {},
    lessonsFilterString: '',
    fetchingLessons: false,
    fetchedAt: null,
    lessonsAwaitingAPIResponse: []
  }

  switch (action.type) {
    case 'LESSONS_RESET':
      return Object.assign({}, state, {
        fetchingLessons: false,
        fetchedAt: null,
        lessons: {},
        lessonsAwaitingAPIResponse: []
      })

    case 'LESSON_LIST':
      return Object.assign({}, state, {
        fetchingLessons: true,
        lessonsAwaitingAPIResponse: []
      })

    case 'LESSON_LIST_SUCCESS':
      return Object.assign({}, state, {
        fetchingLessons: false,
        fetchedAt: Date.now(),
        lessons: Object.assign({}, state.lessons, Object.keys(action.lessonList)
          .reduce(function (acc, lessonId) {
            const existingSession = state.lessons[lessonId]
            acc[lessonId] = Object.assign({}, {
              groups: (existingSession && existingSession.groups) ? existingSession.groups : []
            }, action.lessonList[lessonId])
            return acc
          }, {})
        )
      })

    case 'LESSONS_FETCHED':
      return Object.assign({}, state, {
        lessons: Object.assign({}, state.lessons, Object.keys(action.lessonList)
          .reduce(function (acc, lessonId) {
            const existingSession = state.lessons[lessonId]
            acc[lessonId] = Object.assign({}, {
              groups: (existingSession && existingSession.groups) ? existingSession.groups : []
            }, action.lessonList[lessonId])
            return acc
          }, {})
        )
      })

    case 'LESSON_LIST_FAILURE':
      return Object.assign({}, state, {
        fetchingLessons: false,
        fetchedAt: Date.now()
      })

    case 'LESSONS_FILTER':
      return Object.assign({}, state, {
        lessonsFilterString: action.lessonsFilterString
      })

    case 'LESSON_ADD_GROUPS':
    case 'LESSON_REMOVE_GROUP':
      return Object.assign({}, state, {
        lessonsAwaitingAPIResponse: [
          ...state.lessonsAwaitingAPIResponse,
          action.lessonId
        ]
      })

    case 'LESSON_ADD_GROUPS_SUCCESS':
      const existingLesson = state.lessons[action.lessonId]
      return Object.assign({}, state, {
        lessons: Object.assign({}, state.lessons, {
          [action.lessonId]: Object.assign({}, existingLesson, {
            groups: [
              ...(existingLesson && existingLesson.groups) ? existingLesson.groups : [],
              ...action.groupIds
            ]
          })
        }),
        lessonsAwaitingAPIResponse: [
          ...state.lessonsAwaitingAPIResponse.filter(lessonId => lessonId !== action.lessonId)
        ]
      })

    case 'LESSON_REMOVE_GROUP_SUCCESS':
      return Object.assign({}, state, {
        lessons: Object.assign({}, state.lessons, {
          [action.lessonId]: Object.assign({}, state.lessons[action.lessonId], {
            groups: state.lessons[action.lessonId].groups.filter(groupId => groupId !== action.groupId)
          })
        }),
        lessonsAwaitingAPIResponse: [
          ...state.lessonsAwaitingAPIResponse.filter(lessonId => lessonId !== action.lessonId)
        ]
      })

    default:
      return state
  }
}
