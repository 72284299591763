import request from 'superagent'
import { user } from '../actions'

export default class APIRequest {
  constructor (domain = process.env.REACT_APP_API_URL) {
    // always ensure the domain ends with a slash
    if (domain.slice(domain.length) !== '/') {
      domain += '/'
    }
    this._domain = domain

    return this
  }

  _buildIncludes (includes) {
    return includes && includes instanceof Array ? '?include=' + includes.join() : ''
  }

  _catchAPIError (dispatch, err) {
    if (err.status === 401) {
      const token = localStorage.getItem('apiToken')

      dispatch(user.logout())

      if (token) {
        window.location.href = '/login?sessionExpired=true'
      }
    }

    throw err
  }

  static _init () {
    return this instanceof APIRequest ? this : new APIRequest()
  }

  static _addAuthentication (req) {
    // add in the token if we have one
    let token = localStorage.getItem('apiToken')

    if (token) {
      req.set('Authorization', `Bearer ${token}`)
    }
    return req
  }

  static get (dispatch, endpoint, includes = null) {
    let instance = this._init()
    // join our includes array, to add as a get param
    const includeParams = instance._buildIncludes(includes)
    const req = request.get(instance._domain + endpoint + includeParams)
    return this._addAuthentication(req).catch(instance._catchAPIError.bind(null, dispatch))
  }

  static post (dispatch, endpoint, payload = {}, includes = null) {
    let instance = this._init()
    // join our includes array, to add as a get param
    const includeParams = instance._buildIncludes(includes)
    const req = request.post(instance._domain + endpoint + includeParams).send(payload)
    return this._addAuthentication(req).catch(instance._catchAPIError.bind(null, dispatch))
  }

  static patch (dispatch, endpoint, payload = {}, includes = null) {
    let instance = this._init()
    payload._method = 'PATCH'
    // join our includes array, to add as a get param
    const includeParams = instance._buildIncludes(includes)
    const req = request.post(instance._domain + endpoint + includeParams).send(payload)
    return this._addAuthentication(req).catch(instance._catchAPIError.bind(null, dispatch))
  }

  static delete (dispatch, endpoint, includes = null) {
    let instance = this._init()
    // join our includes array, to add as a get param
    const includeParams = instance._buildIncludes(includes)
    const req = request.post(instance._domain + endpoint + includeParams).send({ _method: 'DELETE' })
    return this._addAuthentication(req).catch(instance._catchAPIError.bind(null, dispatch))
  }
}
