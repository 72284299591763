export default function itineraries (state, action) {
  state = state || {
    itineraries: {}
  }

  switch (action.type) {
    case 'ITINERARY_GET_SUCCESS':
      return Object.assign({}, state, {
        itineraries: Object.assign({}, state.itineraries, {
          [action.bookingId]: action.itinerary
        })
      })

    default:
      return state
  }
}
