import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { active, transition } from './ToggleAngleIcon.module.scss'

const ToggleAngleIcon = props => (
  <FontAwesomeIcon
    className={classNames(props.className, { clickable: true, [transition]: true, [active]: props.isActive })}
    onClick={() => props.onClick()}
    icon={faAngleDown}
  />
)

ToggleAngleIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func
}

ToggleAngleIcon.defaultProps = {
  isActive: false,
  onClick: () => {}
}

export default ToggleAngleIcon
