import { normalize } from 'normalizr'
import APIRequest from '../services/api.service'
import SchemaService from '../services/schema.service'

const slots = {
  update: (slot, includes = []) => {
    return (dispatch) => {
      dispatch({ type: 'SLOT_UPDATE' })
      return APIRequest
        .patch(dispatch, `slots/${slot.id}`, slot, includes)
        .then((res) => {
          const output = normalize(SchemaService.helpers.removeNestedData(res.body.data), SchemaService.schemas.slot)

          return dispatch({
            type: 'SLOT_UPDATE_SUCCESS',
            slot: output.entities.slots[output.result]
          })
        })
        .catch((err) => {
          console.error('failed to update slot', err.message)
          dispatch({ type: 'SLOT_UPDATE_FAILURE', error: err.message })
          throw err
        })
    }
  }
}

export default slots
