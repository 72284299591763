export default function eventNotificationRecipientTypes (state, action) {
  state = state || {
    error: null,
    eventNotificationRecipientTypes: {},
    eventNotificationRecipientTypesList: []
  }

  switch (action.type) {
    case 'EVENT_NOTIFICATION_RECIPIENT_TYPE_LIST':
    case 'EVENT_NOTIFICATION_RECIPIENT_TYPE_LIST_FAILURE':
      return Object.assign({}, state, {
        error: action.error
      })
    case 'EVENT_NOTIFICATION_RECIPIENT_TYPE_LIST_SUCCESS':
      return Object.assign({}, state, {
        eventNotificationRecipientTypes: Object.assign(
          {},
          state.eventNotificationRecipientTypes,
          action.eventNotificationRecipientTypes
        ),
        eventNotificationRecipientTypesList: action.eventNotificationRecipientTypesList
      })

    default:
      return state
  }
}
