import FlowService from '../services/flow.service'

const steps = {
  next: (eventModel, currentStepEndpoint) => {
    return (dispatch) => {
      const newFlowState = FlowService.next(eventModel.type_id, currentStepEndpoint)
      const newEndpoint = newFlowState.find(step => step.isActive).endpoint
      return new Promise((resolve, reject) => resolve(
        dispatch({
          type: 'STEP_CHANGE',
          flow: newFlowState,
          newEndpoint: `/events/${eventModel.id}/edit/${newEndpoint}`
        })
      ))
    }
  },
  previous: (eventModel, currentStepEndpoint) => {
    return (dispatch) => {
      const newFlowState = FlowService.previous(eventModel.type_id, currentStepEndpoint)
      const newEndpoint = newFlowState.find(step => step.isActive).endpoint
      return new Promise((resolve, reject) => resolve(
        dispatch({
          type: 'STEP_CHANGE',
          flow: newFlowState,
          newEndpoint: `/events/${eventModel.id}/edit/${newEndpoint}`
        })
      ))
    }
  },
  get: (eventModel, currentStepEndpoint) => {
    return (dispatch) => {
      const newFlowState = FlowService.current(eventModel.type_id, currentStepEndpoint)
      return dispatch({ type: 'STEP_CHANGE', flow: newFlowState })
    }
  }
}

export default steps
