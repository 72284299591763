import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Footer = props => (
  <footer className='footer bg-dark text-light mt-2'>
    <Container className='text-center my-4'>
      <Row>
        <Col>
          <p className='mb-2'>
            {new Date().getUTCFullYear()} Copyright{' '}
            <a href='http://www.schoolbooking.com' target='_blank' rel='noopener noreferrer'>
              SchoolBooking Ltd
            </a>
          </p>
        </Col>
      </Row>
      <Row>
        <Col className='text-center'>
          <p className='mb-0 extra-small'>build: {process.env.REACT_APP_SB_BUILD_NO || 'n/a'}</p>
        </Col>
      </Row>
    </Container>
  </footer>
)

Footer.propTypes = {}

export default Footer
