import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import PropTypes from 'prop-types'

const Loading = ({ message }) => (
  <Container>
    <Row>
      <Col xs={12}>
        <div className='text-center py-3 my-3'>
          <svg xmlns='http://www.w3.org/2000/svg' width='96' height='96' viewBox='0 0 38 38' className='my-3'>
            <defs>
              <linearGradient x1='8.042%' y1='0%' x2='65.682%' y2='23.865%' id='a'>
                <stop stopColor='#FFA322' stopOpacity='0' offset='0%' />
                <stop stopColor='#FFA322' stopOpacity='.631' offset='63.146%' />
                <stop stopColor='#FFA322' offset='100%' />
              </linearGradient>
            </defs>
            <g fill='none' fillRule='evenodd'>
              <g transform='translate(1 1)'>
                <path
                  d='M36 18c0-9.94-8.06-18-18-18'
                  id='Oval-2'
                  stroke='url(#a)'
                  strokeWidth='2'
                  transform='rotate(233.119 18 18)'
                >
                  <animateTransform
                    attributeName='transform'
                    type='rotate'
                    from='0 18 18'
                    to='360 18 18'
                    dur='0.9s'
                    repeatCount='indefinite'
                  />
                </path>
                <circle fill='#FFA322' cx='36' cy='18' r='1' transform='rotate(233.119 18 18)'>
                  <animateTransform
                    attributeName='transform'
                    type='rotate'
                    from='0 18 18'
                    to='360 18 18'
                    dur='0.9s'
                    repeatCount='indefinite'
                  />
                </circle>
              </g>
            </g>
          </svg>
        </div>
      </Col>
    </Row>
    {message && (
      <Row>
        <div className='w-100 text-center'>
          <h3>{message.title}</h3>
          <p>{message.content}</p>
        </div>
      </Row>
    )}
  </Container>
)

export default Loading

Loading.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  })
}

Loading.defaultProps = {
  message: null
}
